import { init, RematchRootState, RematchDispatch } from '@rematch/core'
import * as models from './models'
import { QueryClient } from 'react-query'

const store = init<typeof models>({ models })

export type Dispatch = RematchDispatch<typeof models>
export type RootState = RematchRootState<typeof models>

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export default store