import React, { useEffect, useState, FC, CSSProperties, useCallback } from 'react';
import BraftEditor from 'braft-editor'
import { FormInstance } from "antd/lib/form";
import axios from "axios";
import { OSS_SIGN } from "consts/url";
import { getRandomString } from "utils/common";
import 'braft-editor/dist/index.css'
import './index.css'

export interface EditorProps {
  form: FormInstance,
  field: string, // 字段名称
  initialValues: any, // 默认值
  placeholder?: string,
  styleObj?: CSSProperties,
  getContent?: Function,
  raw?: string
}

interface Config {
  accessid: string,
  host: string,
  policy: string,
  signature: string,
  expire: number,
  callback: string,
  dir: string
}

const Editor: FC<EditorProps> = (props) => {
  const { form, field, styleObj, getContent, initialValues, raw, placeholder } = props;
  const [connent, setConnent] = useState(BraftEditor.createEditorState(initialValues ?? null))

  useEffect(() => {
    setConnent(BraftEditor.createEditorState(initialValues))
    form?.setFieldsValue({ [field]: BraftEditor.createEditorState(initialValues).toHTML() })
    if (raw) {
      form?.setFieldsValue({ [raw]: BraftEditor.createEditorState(initialValues).toRAW() })
    }
  }, [props.initialValues])

  const handleChange = (connent) => {
    setConnent(connent)
    if (getContent && typeof getContent === 'function') {
      getContent(connent)
    }

    form?.setFieldsValue({ [field]: connent.toHTML() })
    if (raw) {
      form?.setFieldsValue({ [raw]: connent.toRAW() })
    }
  }

  const handleUpload = useCallback(async (e) => {
    const { data: ossConfig } = await axios.get<Config>(OSS_SIGN)
    const filename = `oss-${Date.now()}-${getRandomString()}.jpg`
    const formData = new FormData()
    const key = ossConfig.dir + filename
    formData.append('key', key)
    formData.append('OSSAccessKeyId', ossConfig.accessid)
    formData.append('policy', ossConfig.policy)
    formData.append('signature', ossConfig.signature)
    formData.append('success_action_status', '200')
    formData.append('callback', ossConfig.callback)
    formData.append('file', e.file)
    await axios.post(ossConfig.host, formData)
    e.success({ url: `${ossConfig.host}/${key}` })
  }, [])

  return (
    <BraftEditor
      className="my-editor"
      style={styleObj}
      onChange={handleChange}
      value={connent}
      placeholder={placeholder ?? '请输入正文内容'}
      media={{
        accepts: {
          video: false,
          audio: false,
        },
        externals: {
          video: false,
          audio: false,
          embed: false,
        },
        pasteImage: true,
        uploadFn: handleUpload
      }}
    />
  )
}

export default Editor;