import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Table,
  Typography,
  Tooltip,
  Tabs,
  Row,
  Col,
  Image,
  message,
  Modal,
  InputNumber,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import device from "services/device";
import { LockerModel } from "types/reserve";

const List: FC<{ setLoading: Function }> = (props) => {
  const history = useHistory()
  const [addDevice] = Form.useForm()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [total, setTotal] = useState<number>(0)
  const [visible, setVisible] = useState<boolean>(false)
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false)
  const [current, setCurrent] = useState<LockerModel>()
  const [dataSource, setDataSource] = useState<LockerModel[]>([])

  const fetchList = useCallback(async () => {
    try {
      const data = await device.lockerList({ page, page_size: pageSize })
      setDataSource(data.list)
      setTotal(data.total)
      props.setLoading(false)
    } catch (e) {
      message.error(e?.data?.message || e?.data || e?.message || e || '网络错误')
      props.setLoading(false)
    }
  }, [page, pageSize, props])

  useEffect(() => {
    (async () => {
      props.setLoading(true)
      await fetchList()
    })()
  }, [page, pageSize])


  const handleSubmit = useCallback(async (value) => {
    try {
      await device.create(value)
      message.success('添加成功')
      setVisible(false)
      await fetchList()
    } catch (e) {
      console.log(e)
      message.error(e)
    }
  }, [fetchList])

  const columns = [{
    key: 'name',
    dataIndex: 'name',
    title: '储物柜名称',
    ellipsis: {
      showTitle: false,
    },
    render: lockerName => (
      <Tooltip placement="topLeft" overlay={null}>
        {lockerName}
      </Tooltip>
    ),
  }, {
    key: 'cabinet_id',
    dataIndex: 'cabinet_id',
    title: '设备ID'
  }, {
    key: 'box_count',
    dataIndex: 'box_count',
    title: '柜子数量'
  }, {
    key: '4',
    dataIndex: 'operation',
    title: '操作',
    render: (_, value) => {
      return (
        <Space key={value.cabinet_id}>
          <Typography.Link onClick={() => {
            Modal.confirm({
              title: '编辑后储物柜配置将立即生效!',
              type: 'warn',
              centered: true,
              onOk: () => {
                setModalIsVisible(true)
                setCurrent(value)
              }
            });
          }}>编辑</Typography.Link>
          <Typography.Link onClick={() => {
            history.push(`/Control/lockers?id=${value.id}&name=${value.name}`)
          }}>管理</Typography.Link>
          <Typography.Link onClick={() => {
            Modal.confirm({
              title: '确定删除该储物柜吗？？',
              type: 'warn',
              centered: true,
              onOk: async () => {
                try {
                  await device.deleteLockers({ id: value.id })
                  message.success('删除成功')
                  // await fetchList()
                } catch (e) {
                  message.error(e)
                }
              },
            });
          }}>删除</Typography.Link>
          <Typography.Link onClick={() => {
            history.push(`/log/lockers?id=${value.id}&name=${value.name}`)
          }}>日志</Typography.Link>
          <Tooltip
            placement="bottomLeft"
            style={{ maxWidth: 600 }}
            color="#fff"
            title={
              <Tabs centered>
                <Tabs.TabPane key="1" tab="H5">
                  <Row>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <Image width={200} height={200} src={value.qrcode_h5}/>
                    </Col>
                    <Col span={24} style={{ textAlign: 'center' }}>
                      <a href={value.qrcode_h5} download>下载二维码</a>
                    </Col>
                    <Col span={24} style={{ marginTop: 12 }}>
                      <Row gutter={[0, 10]} justify="space-between">
                        <Col span={14}><Input value={value.link_h5} disabled style={{ width: 220 }}/></Col>
                        <Col>
                          <Typography.Paragraph
                            copyable={{ text: value.link_h5, icon: [<Button>复制</Button>, <Button>复制</Button>] }}/>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Tabs.TabPane>
              </Tabs>
            }
          >
            <Button type="link" style={{ padding: 0 }}>查看二维码</Button>
          </Tooltip>
        </Space>
      )
    }
  }]

  return (
    <Fragment>
      <Space style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          icon={<PlusOutlined/>}
          onClick={() => setVisible(true)}
        >添加储物柜</Button>
      </Space>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={dataSource}
        pagination={{
          current: page,
          total,
          pageSize,
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: _page => setPage(_page),
          onShowSizeChange: (_, value) => setPageSize(value)
        }}
      />
      {
        visible ? <Drawer
            title={`添加储物柜`}
            visible={visible}
            width={512}
            footer={
              <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => setVisible(false)}>取消</Button>
                <Button
                  type="primary"
                  onClick={() => {
                    addDevice.submit()
                  }}
                >保存</Button>
              </Space>
            }
            onClose={() => setVisible(false)}
          >
            <Typography.Title level={5}>储物柜信息</Typography.Title>
            <Form layout="vertical" form={addDevice} initialValues={{ type: 'qianmingyun' }} onFinish={handleSubmit}>
              <Form.Item label="储物柜名称" name="name" rules={[{ required: true }]}>
                <Input placeholder="请输入储物柜名称"/>
              </Form.Item>
              <Form.Item label="设备ID" name="cabinet_id" rules={[{ required: true }]}>
                <Input placeholder="请输入储物柜名称设备ID"/>
              </Form.Item>
              <Form.Item label="储物柜类型" name="type" rules={[{ required: true }]}>
                <Select>
                  <Select.Option value="qianmingyun">千鸣云</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item label="储物柜数量" name="box_count" rules={[{ required: true, min: 1, max: 200, type: 'number' }]}>
                <InputNumber placeholder="请输入柜子数量" style={{ width: '100%' }}/>
              </Form.Item>
            </Form>
          </Drawer>
          : null
      }

      {
        modalIsVisible ? (
          <Modal
            title="编辑"
            visible={modalIsVisible}
            onCancel={() => setModalIsVisible(false)}
            onOk={async () => {
              try {
                await device.update({ id: current.id, name: current.name })
                message.success('编辑成功')
                setModalIsVisible(false)
                await fetchList()
              } catch (e) {
                console.log(e.data.message || e.data || e.message || e || '网络错误')
                message.error(e)
              }
            }}
          >
            <Form.Item label="储物柜名称">
              <Input value={current.name} onChange={({ target: { value } }) => {
                setCurrent({ ...current, name: value })
              }}/>
            </Form.Item>
          </Modal>
        ) : null
      }

    </Fragment>
  )
}

export default List