import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input, message,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography
} from "antd";
import { useDebounceFn } from "utils/hooks";
import device from "services/device";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { exportExcel } from "components/XLSX";

const consumptionTypeMap = {
  1: '押金',
  2: '使用储物柜',
  3: '续租',
  4: '超时支付',
}

const orderStatusMap = {
  1: {
    text: '待付款',
    bgc: 'warning'
  },
  2: {
    text: '已付款',
    bgc: 'success'
  },
  3: {
    text: '已完成',
    bgc: ''
  },
  4: {
    text: '已取消',
    bgc: 'error'
  },
  5: {
    text: '已退款',
    bgc: 'error'
  },
  6: {
    text: '已超时',
    bgc: 'error'
  },
}

const Order: FC = () => {
  const history = useHistory()
  const [listIsFetching, setListIsFetching] = useState<boolean>(false)
  const [rowSelection, setRowSelection] = useState([])
  const [keyword, setKeyword] = useState<string>(undefined)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [total, setTotal] = useState<number>(0)
  const [time, setTime] = useState<string[]>(undefined)
  const [dataSource, setDataSource] = useState([])
  const [consumption_type, setConsumptionType] = useState(0)
  const [order_status, setOrderStatus] = useState(0)

  const { run: fetchData } = useDebounceFn((keyword: string, time: string[], page: number, page_size: number, consumption_type, order_status) => {
    device.orderLists({
      keyword,
      start_date: time?.length ? time[0] : void 0,
      end_data: time?.length ? time[1] : void 0,
      page,
      page_size,
      consumption_type: consumption_type ? consumption_type : undefined,
      order_status: order_status ? order_status : undefined
    }).then(res => {
      setDataSource(res.list);
      setTotal(res.total)
    }).finally(() => {
      setListIsFetching(false)
    })
  }, { wait: 1000 })

  useEffect(() => {
    (async () => {
      setListIsFetching(true)
      await fetchData(keyword, time, page, pageSize, consumption_type, order_status)
    })()
  }, [keyword, time, page, pageSize, consumption_type, order_status])

  const handleChangeTime = useCallback(async (value: any) => {
    let temp = value === null ? undefined : [
      value[0].format('YYYY-MM-DD'),
      value[1].format('YYYY-MM-DD')
    ]
    setTime(temp)
  }, [])

  /**
   * 导出excel
   */
  const handleExport = useCallback(() => {
    if (!rowSelection.length) return message.error('请至少选择一列数据')
    const exportData = dataSource.filter(item => rowSelection.includes(item.id))
    setListIsFetching(true)
    const hide = message.loading('正在导出Excel...')
    const header = ['订单号', '下单时间', '储物柜', '订单类型', '用户姓名', '联系方式', '使用时间', '订单金额', '订单状态']

    try {
      let arr = exportData.map(item => ({
        订单号: item.order_id,
        下单时间: item.created_at,
        储物柜: consumptionTypeMap[item.type] === '押金' ? '' : `${item.locker_name}${item?.locker_number}号`,
        订单类型: consumptionTypeMap[item.type],
        用户姓名: item.name,
        联系方式: item.phone,
        使用时间: item.rental_time,
        订单金额: item.price,
        订单状态: orderStatusMap[item.state].text
      }))
      // @ts-ignore
      exportExcel(arr, '订单列表数据', header)
      setListIsFetching(false)
      hide()
    } catch (e) {
      console.log(e)
      setListIsFetching(false)
      hide()
    }
  }, [dataSource, rowSelection])

  const columns = [
    {
      title: '订单号',
      dataIndex: 'order_id',
      render: (_, record) => (
        <Fragment>
          {record.order_id} <br/>
          下单时间：{record.created_at}
        </Fragment>
      )
    },
    {
      title: '订单类型',
      dataIndex: 'type',
      render: type => consumptionTypeMap[type]
    },
    {
      title: '用户名',
      dataIndex: 'name',
      render: (_, record) => (
        <Fragment>
          {record.name} <br/>
          {record.phone}
        </Fragment>
      )
    },
    {
      title: '租用时间',
      dataIndex: 'locker_name',
      render: (_, record) => (
        <Fragment>
          {record.locker_name} {record?.locker_number} <br/>
          {record.rental_time}
        </Fragment>
      )
    },
    {
      title: '订单状态',
      dataIndex: 'state',
      render: state => (
        <Tag color={orderStatusMap[state].bgc}>{orderStatusMap[state].text}</Tag>
      )
    },
    {
      title: '订单金额',
      dataIndex: 'price',
      render: (_, record) => (
        <Fragment>¥{Number(record.price)?.toFixed(2)}</Fragment>
      )
    },
    {
      title: '操作',
      width: 200,
      render: (_, record) => (
        <Typography.Link onClick={() => {
          history.push(`/reserve/device/rent/order?rend_id=${record.id}`)
        }}>查看详情</Typography.Link>
      )
    },
  ];

  return (
    <Fragment>
      <Row justify="space-between">
        <Row gutter={[20, 0]} style={{ width: '100%' }}>
          <Col span={5}>
            <Form.Item label='订单状态'>
              <Select placeholder='默认全部状态' value={order_status} onSelect={setOrderStatus} options={[
                { label: '全部', value: 0 },
                { label: '待付款', value: 1 },
                { label: '已付款', value: 2 },
                { label: '已完成', value: 3 },
                { label: '已取消', value: 4 },
                { label: '已退款', value: 5 },
              ]}/>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label='消费类型'>
              <Select placeholder='默认全部类型' value={consumption_type} onSelect={setConsumptionType} options={[
                { label: '全部', value: 0 },
                { label: '押金', value: 1 },
                { label: '使用储物柜', value: 2 },
                { label: '续租', value: 3 },
                { label: '超时支付', value: 4 },
              ]}/>
            </Form.Item>
          </Col>
        </Row>
        <Col>
          <Space>
            <Button onClick={handleExport}>导出EXCEL</Button>
          </Space>
        </Col>
        <Row gutter={[20, 0]}>
          <Col>
            <Form.Item>
              <Input
                allowClear
                placeholder="请输入用户姓名/手机号"
                onChange={e => setKeyword(e.target.value !== '' ? e.target.value : undefined)}
                suffix={<SearchOutlined/>}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <DatePicker.RangePicker
                picker="date"
                value={time?.length ? [moment(time[0]), moment(time[1])] : null}
                onChange={(value) => handleChangeTime(value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Row>
      <Table
        rowKey='id'
        loading={listIsFetching}
        rowSelection={{
          selectedRowKeys: rowSelection,
          onChange: selectedRowKeys => {
            setRowSelection(selectedRowKeys)
          }
        }}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          current: page,
          total,
          pageSize,
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: _page => setPage(_page),
          onShowSizeChange: (_, _pageSize) => setPageSize(_pageSize)
        }}
      />
    </Fragment>
  )
}

export default Order