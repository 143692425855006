import React, { FC } from "react";
import {
  FormInstance,
  Form,
  Typography,
  Row,
  Col,
  Switch,
  Select,
  InputNumber,
  Divider,
  Input,
} from "antd";
import Editor from "components/Editor";
import styles from './common.module.css'
import regexp from "consts/regexp";
import { useUpdate } from "utils/hooks";

interface Props {
  rentForm: FormInstance
}

const Rent: FC<Props> = (props) => {
  const update = useUpdate()

  return (
    <>
      <Row gutter={[24, 10]} align="middle">
        <Col span={24}><Typography.Title level={5}>租用条件</Typography.Title></Col>
        <Col span={24}>
          <Form.Item
            label="预约后可租用"
            name="available_after_appointment"
            style={{ flexDirection: 'row', alignItems: 'baseline' }}
          >
            <Switch
              checked={props.rentForm.getFieldValue('available_after_appointment')}
              onChange={e => {
                props.rentForm.setFieldsValue({available_after_appointment: e})
                update()
              }}
              checkedChildren="开启"
              unCheckedChildren="关闭"
              style={{ marginLeft: 10 }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row gutter={[90, 24]}>
            <Col span={8}>
              <Form.Item name="max_leases" label="最大租用数">
                <InputNumber<number> className={styles.formItem} placeholder="请输入数字，且为整数"/>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="is_payment_automatic_opening" label="支付成功是否自动开柜（选项：是；否）">
                <Select
                  className={styles.formItem}
                  placeholder="请选择"
                  options={[{label: '是', value: '1'}, { label: '否', value: '0' }]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider/>

      <Row gutter={[24, 10]} align="middle">
        <Col span={24}><Typography.Title level={5}>联系客服</Typography.Title></Col>
        <Col span={8}>
          <Form.Item label="联系方式一" name="customer_service_number_1" rules={[{ pattern: regexp.phone, message: '格式不正确' }]}>
            <Input className={styles.formItem} placeholder="请输入"/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="联系方式二" name="customer_service_number_2" rules={[{ pattern: regexp.phone, message: '格式不正确' }]}>
            <Input className={styles.formItem} placeholder="请输入"/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="联系方式三" name="customer_service_number_3" rules={[{ pattern: regexp.phone, message: '格式不正确' }]}>
            <Input className={styles.formItem} placeholder="请输入"/>
          </Form.Item>
        </Col>
      </Row>

      <Divider/>

      <Row gutter={[24, 10]} align="middle">
        <Col span={24}><Typography.Title level={5}>租用协议</Typography.Title></Col>
        <Col>
          <Form.Item noStyle name="rental_agreement">
            <Editor
              form={props.rentForm}
              field={'rental_agreement'}
              initialValues={props.rentForm.getFieldValue('rental_agreement')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider/>

      <Row gutter={[24, 10]} align="middle">
        <Col span={24}><Typography.Title level={5}>温馨提示</Typography.Title></Col>
        <Col>
          <Form.Item noStyle name="reminder">
            <Editor
              form={props.rentForm}
              field={'reminder'}
              initialValues={props.rentForm.getFieldValue('reminder')}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default Rent