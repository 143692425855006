import React, { CSSProperties } from "react"
import { createFromIconfontCN } from "@ant-design/icons"
import { ICONFONTCN, ICONFONTCN_WECHAT } from "consts/url"

const IconFont = createFromIconfontCN({ scriptUrl: ICONFONTCN })

const IconFontWechat = createFromIconfontCN({ scriptUrl: ICONFONTCN_WECHAT })

interface Props {
  type: string;
  color?: string;
  size?: number;
  style?: CSSProperties;
}

function Icon(props: Props) {
  const { type, color, size, style } = props
  return (
    <IconFont type={type} style={{ color: color, fontSize: size, ...style }} />
  )
}

export function IconWechat(props: Props) {
  const { type, color, size, style } = props
  return (
    <IconFontWechat type={type} style={{ color: color, fontSize: size, ...style }} />
  )
}


export default Icon
