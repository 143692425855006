import React, { FC, useEffect, useState, useCallback } from 'react'
import { Block, InputBox } from 'components'
import { finance } from 'services'
import { Order, Country, ListPayload } from 'services/finance'
import { columns, payList, orderList } from 'consts/finance'
import { Table, Select, DatePicker, Input, Button, Collapse, Empty } from 'antd'
import { Row, Hr, ButtonBar, Arrow, Header, Title } from './style'
import { FormatDay } from 'utils/time'
import moment from 'moment'
import { SearchOutlined, RightOutlined } from '@ant-design/icons'
import { Dialog } from '../Model'

interface DetailType {
  countryList: Country[]
}

const Detail: FC<DetailType> = (props) => {
  const { Option } = Select
  const { RangePicker } = DatePicker
  const { Panel } = Collapse
  const nowDate = FormatDay(new Date())
  const [data, setData] = useState<Order[]>([])
  const [current, setCurrent] = useState<number>(1)
  const [size, setSize] = useState<number>(20)
  const [total, setTotal] = useState<number>(0)
  const [startTime, setStartTime] = useState<string>(nowDate)
  const [endTime, setEndTime] = useState<string>(nowDate)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isShow, setIsShow] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [country, setCountry] = useState<string>('')
  const [orderType, setOrderType] = useState<string>('')
  const [payType, setPayType] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [payload, setPayload] = useState<ListPayload>({
    start: nowDate,
    end: nowDate,
  })

  //请求列表数据
  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const data = await finance.getList({
        page: current,
        page_size: size,
        ...payload
      })
      setIsLoading(false)
      const total = Number(data.total_num)
      if (total) {
        setTotal(total)
        setData(data.orders.map(item => ({
          ...item,
          amount: `${(Number(item.amount) / 100).toFixed(2)}`,
          payed: `${(Number(item.payed) / 100).toFixed(2)}`,
          flowing: `${(Number(item.flowing) / 100).toFixed(2)}`
        })))
      } else {
        setTotal(1)
        setData([])
      }
    })()
  }, [current, size, payload])

  //设置参数
  const submit = useCallback(() => {
    setPayload({
      branch: country,
      type: orderType,
      method: payType,
      q: search,
      start: startTime,
      end: endTime,
    })
    setCurrent(1)
  }, [search, startTime, endTime, orderType, payType, country, nowDate])

  //重置参数
  const reset = useCallback(() => {
    setPayload({
      branch: '',
      type: '',
      method: '',
      q: '',
    })
    setCountry('')
    setOrderType('')
    setPayType('')
    setSearch('')
    setStartTime(nowDate)
    setEndTime(nowDate)
    setCurrent(1)
  }, [nowDate])

  return (
    <React.Fragment>
      <Block loading={isLoading}>
        <Collapse
          activeKey={[1]}
          expandIconPosition='right'
          style={{ padding: 0 }}
          ghost
        >
          <Panel
            key={1}
            forceRender={true}
            showArrow={false}
            header={
              <Row style={{ marginLeft: '-12px' }}>
                <InputBox title='门店信息' style={{ marginRight: '40px' }}>
                  <Select value={country} onChange={(value) => setCountry(value)} style={{ width: 200 }}>
                    <Option value=''>全部门店</Option>
                    {props.countryList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                </InputBox>
                <InputBox title='消费类型' style={{ marginRight: '40px' }}>
                  <Select value={orderType} onChange={(value) => { setOrderType(value) }} style={{ width: 200 }}>
                    {orderList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                </InputBox>
                <InputBox title='支付类型' style={{ marginRight: '40px' }}>
                  <Select value={payType} onChange={(value) => { setPayType(value) }} placeholder='请选择' style={{ width: 200 }}>
                    {payList.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                  </Select>
                </InputBox>
                {/* <Arrow onClick={() => { setIsShow(!isShow) }}>
                  高级筛选&nbsp;&nbsp;<RightOutlined rotate={isShow ? 90 : 0} />
                </Arrow> */}
              </Row>
            }>
            <React.Fragment>
              <Row style={{ marginLeft: '-16px' }}>
                <Hr />
                <InputBox title='搜索' style={{ marginRight: '40px' }}>
                  <Input
                    style={{ width: '320px' }}
                    suffix={<SearchOutlined />}
                    placeholder='请输入订单号/手机号/消费商品'
                    onBlur={(e) => { setSearch(e.currentTarget.value) }}
                  />
                </InputBox>
                <InputBox title='选择时间' style={{ marginRight: '40px' }}>
                  <RangePicker
                    defaultValue={[moment(startTime), moment(endTime)]}
                    onChange={(_date, dateString) => {
                      if (dateString && dateString.length) {
                        setStartTime(dateString[0])
                        setEndTime(dateString[1])
                      }
                    }}
                  />
                </InputBox>
              </Row>
            </React.Fragment>
          </Panel>
        </Collapse>
        <ButtonBar>
          <Button type='primary' onClick={submit}>搜索</Button>
          <Button type='default' onClick={reset}>重置</Button>
        </ButtonBar>
        <Row>
          <Header>
            <Title>导出报表</Title>
            <Button type='primary' onClick={() => { setIsOpen(true) }}>导出报表</Button>
          </Header>
        </Row>
        <Table
          pagination={{
            total: total,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSize: size,
            pageSizeOptions: ['10', '20', '50', '100'],
            onChange: (_current, _size) => {
              if (_current !== current) setCurrent(_current)
              if (_size !== size) setSize(_size!)
            },
          }}
          dataSource={data}
          columns={columns}
          locale={{
            emptyText: <Empty description="当前暂无符合条件的数据" />
          }}
        />
      </Block >
      <Dialog
        countryList={props.countryList}
        isOpen={isOpen}
        onClose={() => { setIsOpen(false) }}
      />
    </React.Fragment>
  )
}

export default Detail