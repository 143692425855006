/*
 * @Author: your name
 * @Date: 2021-03-22 09:24:21
 * @LastEditTime: 2021-03-22 14:46:05
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/Block/index.tsx
 */
import React, { FC, ReactNode, CSSProperties } from 'react'
import { Body, Header, Title, Main, Loading, Bottom } from './style'
import { Spin } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

interface BlockProps {
  title?: ReactNode,
  description?: ReactNode,
  children?: ReactNode,
  loading?: boolean,
  showBack?: boolean,
  style?: CSSProperties,
  bottom?: any | ReactNode,
  stretch?: number;
  bodyStyle?: CSSProperties;
}

const Block: FC<BlockProps> = (props) => {
  return (
    <Body style={{
      ...props.bodyStyle,
      height: props.stretch ? `calc(100vh - ${props.stretch}px)` : 'auto',
      paddingBottom: props.stretch ? 64 : 0
    }}>
      {
        props.title || props.description ?
          <Header>
            <Title>
              {
                props.showBack &&
                <ArrowLeftOutlined
                  style={{ marginRight: 10 }}
                  onClick={() => window.history.back()}
                />
              }
              {props.title ? props.title : <div />}
            </Title>
            <div>{props.description ? props.description : <div />}</div>
          </Header>
          : null
      }
      <Main style={props.style}>{props.children}</Main>
      {props.loading ? <Loading><Spin size="large" /></Loading> : null}
      {props.bottom ? <Bottom style={{position: props.stretch ? 'absolute' : 'static'}}>{props.bottom}</Bottom> : null}
    </Body>
  )
}

export default Block