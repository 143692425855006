import axios from 'axios'
import queryString from 'query-string'
import { HttpMethod, Options, Result } from '../../types/http'
import qs from 'qs'
import { baseURL } from 'consts/url'

const service = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  withCredentials: true,
})

const request = (method: HttpMethod, url: string, options: Options) => service({
  method,
  url,
  data: options.type === 'from' ? queryString.stringify(options.data) : JSON.stringify(options.data),
  params: options.params,
  headers: {
    'content-type': "application/x-www-form-urlencoded",
    ...options.headers
  },
})

function get<T>(url: string, data: object = {}, options: object = {}) {
  return new Promise<T>((resolve, reject) => {
    request('GET', `${url}?${qs.stringify(data)}`, options)
      .then((res: { data: Result<T> }) => {
        if (res && res.data && res.data && res.data.code === 200) {
          resolve(res.data.data || undefined)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => { reject(err.response) })
  })
}

function post<T>(url: string, data: object = {}, options: object = {}) {
  return new Promise<T>((resolve, reject) => {
    request('POST', url, { ...options, data })
      .then((res: { data: Result<T> }) => {
        if (res && res.data && res.data && res.data.code === 200) {
          resolve(res.data.data || undefined)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => { reject(err) })
  })
}

function put<T>(url: string, data: object = {}, options: object = {}) {
  return new Promise<T>((resolve, reject) => {
    request('PUT', url, { ...options, data })
      .then((res: { data: Result<T> }) => {
        if (res && res.data && (res.data.code === 200 || 201)) {
          resolve(res.data.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => { reject(err) })
  })
}

function del<T>(url: string, data: object = {}, options: object = {}) {
  return new Promise<T>((resolve, reject) => {
    request('DELETE', `${url}${JSON.stringify(data) === '{}' ? '' : '?'}${qs.stringify(data)}`, options)
      .then((res: { data: Result<T> }) => {
        if (res && res.data && (res.data.code === 200 || 201)) {
          resolve(res.data.data)
        } else {
          reject(res.data.message)
        }
      })
      .catch(err => { reject(err) })
  })
}

export { get, post, del, put }
