/*
 * @Author: your name
 * @Date: 2021-01-13 13:37:18
 * @LastEditTime: 2021-03-22 16:25:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Mall/View/style.ts
 */
import styled from "styled-components"

export const Title = styled.h2`
  margin-top: 22px;
`

export const ButtonBar = styled.div`
  padding: 22px 0;
`

export const StepDescription = styled.div`
  width: 170px;
`

export const AddBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export const AddImageList = styled.div`
  width: 480px;
  overflow: hidden;
`

export const AddImage = styled.div`
  width: 102px;
  height: 102px;
  float: left;
  margin-left: 14px;
  box-sizing: border-box;
  padding: 7px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const AddImageEdit = styled.div`
  position: absolute;
  height: 86px;
  width: 86px;
  top: 8px;
  left:8px;
  background: rgba(0, 0, 0, 0.47);
  color: white;
  text-align: center;
  line-height: 86px;
  z-index:10;
  opacity: 0.1;
  user-select: none;
  
  &:hover{
    opacity: 1;
  }
`

export const AddDescription = styled.p`
  margin-top: 8px;
  margin-left: 30px;
  font-size: 12px;
  color: #ccc;
`

export const AddRadio = styled.div`
  font-size: 12px;
  margin-top: 6px;
  color: #ccc;
`

export const MallName = styled.div`
  display: flex;
`

export const MallNameHeader = styled.img`
  height: 48px;
  width: 48px;
`

export const MallNameText = styled.div`
  margin-left: 24px; 
`

export const MallNameName = styled.div`
  font-size: 14px;
  color: #333;
`

export const MallNameDescription = styled.div`
  font-size: 14px;
  color: #999;
`

export const UploadBody = styled.div`
  width:212px;
  height: 98px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const UploadText = styled.div`
  margin-top: 10px;
  color: #666;
  font-size: 12px;
  text-align: center;
`

export const SwiperImageBox = styled.div`
  width: 212px;
  height: 114px;
  padding: 8px;
  border: 1px solid #ddd;
  float: left;
  position: relative;
  box-sizing: border-box;
`

export const SwiperImage = styled.img`
  height: 98px;
  width: 196px;
`

export const SwiperImageMark = styled.div`
  position: absolute;
  height: 98px;
  width: 196px;
  top: 8px;
  left:8px;
  background: rgba(0, 0, 0, 0.47);
  color: white;
  text-align: center;
  line-height: 90px;
  z-index:10;
  opacity: 0.1;
  user-select: none;
  
  &:hover{
    opacity: 1;
  }
`