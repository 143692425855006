/*
 * @Author: your name
 * @Date: 2021-01-14 11:35:30
 * @LastEditTime: 2021-03-22 14:28:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Mall/View/list.tsx
 */
import { Block, Header, InputBox } from 'components'
import { Button, Table, message, Input, Descriptions, Select, Tabs, Tag } from "antd"
import React, { FC, useCallback, useEffect, useState } from 'react'
import { mall } from 'services'
import { Mall, MallGroupListResult } from 'services/mall'
import { mallColumns } from 'consts/mall'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { MallName, MallNameDescription, MallNameHeader, MallNameName, MallNameText } from './style'
import './style.css'

const List: FC = () => {
  const { TabPane } = Tabs
  const { Option } = Select
  const [list, setList] = useState<Mall[]>([])
  const [groupData, setGroupData] = useState<MallGroupListResult>({ data: [], total: 0 })
  const [groupId, setGroupId] = useState<number>()
  const [keyword, setKeyword] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [minSales, setMinSales] = useState<number>()
  const [maxSales, setMaxSales] = useState<number>()
  const [minPrice, setMinPrice] = useState<number>()
  const [maxPrice, setMaxPrice] = useState<number>()
  const [render, setRender] = useState<number>(1)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(20)
  const [total, setTotal] = useState<number>(0)
  const [type, setType] = useState<number>(0)
  const [selectRowIdList, setSelectRowIdList] = useState<React.ReactText[]>([])

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const data = await mall.getMallGroupList({ page: 1, limit: 100 })
      setGroupData(data)
      setIsLoading(false)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const data = await mall.getMallList({
        page,
        limit,
        type,
        goods_name: keyword,
        category_id: groupId,
        min_sales: minSales,
        max_sales: maxSales,
        min_price: minPrice,
        max_price: maxPrice,
      })
      setTotal(data.total)
      setList(data.data)
      setIsLoading(false)
    })()
  }, [render])

  const handleListData = useCallback((list) => {
    return list.map((item: Mall) => ({
      ...item,
      key: item.id,
      name: (
        <MallName>
          <MallNameHeader src={item.pic_cover} />
          <MallNameText>
            <MallNameName>{item.name}</MallNameName>
            <MallNameDescription>{item.short_description}</MallNameDescription>
          </MallNameText>
        </MallName>
      ),
      is_show: (
        <div>
          {item.is_show === 1 ? <Tag color="success">销售中</Tag> : null}
          {item.is_show === 2 ? <Tag color="warning">{item.timing}上架</Tag> : null}
          {item.is_show === 0 ? <Tag color="default">商品下架</Tag> : null}
        </div>
      ),
      handle: (
        <div>
          <Link to={`/mall/add/${item.id}`}>
            <Button type="link">编辑</Button>
          </Link>
          {item.is_show === 1 ? <Button type="link" onClick={() => { handleChangeMall(0, [item.id]) }}>下架</Button> : null}
          {item.is_show === 0 ? <Button type="link" onClick={() => { handleChangeMall(1, [item.id]) }}>上架</Button> : null}
        </div>
      )
    }))
  }, [render])

  const handleClickSubmit = useCallback(() => {
    setSelectRowIdList([])
    setPage(1)
    setRender(render + 1)
  }, [render])

  const handleChangeMall = useCallback(async (type, list) => {
    if (type > 2) {
      return
    }
    try {
      await mall.editMallState({ is_show: type, ids: list.join() })
      setSelectRowIdList([])
      message.success("修改成功")
      setPage(1)
      setRender(render + 1)
    } catch (err) {
      message.error("修改失败")
    }
  }, [render])

  const handleClickReset = useCallback(() => {
    setMinSales(void 0)
    setMaxSales(void 0)
    setMinPrice(void 0)
    setMaxPrice(void 0)
    setKeyword(void 0)
    setGroupId(void 0)
    setSelectRowIdList([])
    setPage(1)
    setRender(render + 1)
  }, [render])

  return (
    <div>
      <Header
        title="商品列表"
        description={""}
      />
      <Block loading={isLoading} style={{ paddingTop: 20 }}>
        <Descriptions>
          <Descriptions.Item>
            <InputBox title="商品名称">
              <Input
                value={keyword}
                onChange={(e) => { setKeyword(e.target.value) }} style={{ width: 200 }}
                placeholder="请输入关键字"
              />
            </InputBox>
          </Descriptions.Item>
          <Descriptions.Item>
            <InputBox title="商品分组">
              <Select
                value={groupId}
                style={{ width: 200 }}
                onChange={(value: number) => { setGroupId(value) }}
                placeholder="请选择分组"
              >
                {groupData.total ? groupData.data.map(item => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)) : null}
              </Select>
            </InputBox>
          </Descriptions.Item>
          <Descriptions></Descriptions>
          <Descriptions.Item>
            <InputBox title="销量区间">
              <Input
                type="number"
                style={{ width: 88 }}
                value={minSales}
                onChange={(e) => { setMinSales(Number(e.target.value)) }}
                placeholder="最低销量"
              />
              &nbsp;-&nbsp;
              <Input
                type="number"
                style={{ width: 88 }}
                value={maxSales}
                onChange={(e) => { setMaxSales(Number(e.target.value)) }}
                placeholder="最高销量"
              />
            </InputBox>
          </Descriptions.Item>
          <Descriptions.Item>
            <InputBox title="价格区间">
              <Input
                type="number"
                style={{ width: 88 }}
                value={minPrice}
                onChange={(e) => { setMinPrice(Number(e.target.value)) }}
                placeholder="最低价格"
              />
              &nbsp;元&nbsp;-&nbsp;
              <Input
                type="number"
                style={{ width: 88 }}
                value={maxPrice}
                onChange={(e) => { setMaxPrice(Number(e.target.value)) }}
                placeholder="最高价格"
              />
                  &nbsp;元
            </InputBox>
          </Descriptions.Item>
          <Descriptions.Item>
            <Button onClick={handleClickSubmit} type="primary">查询</Button>
            <Button style={{ marginLeft: 8 }} onClick={handleClickReset}>重置</Button>
          </Descriptions.Item>
        </Descriptions>
      </Block>
      <Block loading={isLoading} style={{ paddingTop: 20 }}>
        <Link to='/mall/add'>
          <Button type="primary">
            <PlusOutlined />添加商品
          </Button>
        </Link>
        <Select
          value={2}
          disabled={!Boolean(selectRowIdList.length)}
          style={{ width: 98, marginLeft: 8 }}
          onChange={(value) => handleChangeMall(value, selectRowIdList)}
        >
          <Option value={2}>批量管理</Option>
          <Option value={1}>上架</Option>
          <Option value={0}>下架</Option>
        </Select>
        <Tabs
          activeKey={type.toString()}
          onChange={(value) => {
            setType(Number(value))
            setRender(render + 1)
          }}
          type="card"
          style={{ marginTop: 24 }}
        >
          <TabPane tab="全部商品" key={0} />
          <TabPane tab="在售商品" key={1} />
          <TabPane tab="下架商品" key={2} />
        </Tabs>
        <b style={{ marginTop: 24 }}>
          {type === 0 ? "全部商品列表" : null}
          {type === 1 ? "在售商品列表" : null}
          {type === 2 ? "下架商品列表" : null}
        </b>
        <Table
          columns={mallColumns}
          dataSource={handleListData(list)}
          rowSelection={{
            selectedRowKeys: selectRowIdList,
            onChange: selectedRowKeys => { setSelectRowIdList(selectedRowKeys) }
          }}
          pagination={{
            total: total,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSize: limit,
            pageSizeOptions: ['10', '20', '50', '100'],
            onChange: (_current, _size) => {
              if (_current !== page) setPage(_current)
              if (_size !== limit) setLimit(_size!)
            },
          }}
          style={{ marginTop: 24 }}
          scroll={{ x: 1000 }}
        />
      </Block>
    </div>
  )
}

export default List