/*
 * @Author: your name
 * @Date: 2021-01-13 13:37:18
 * @LastEditTime: 2021-01-22 15:18:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Mall/View/style.ts
 */
import styled from "styled-components"

export const Body = styled.label`
  width: 265px;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #E9E9E9;
  padding: 16px 24px;
  position: relative;
  display: block;
`

export const ButtonBar = styled.div`
  padding: 22px 0;
`

export const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
`

export const Description = styled.div`
  font-size: 12px;
  margin-top: 14px;
`