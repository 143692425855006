import { message } from "antd"
import { queryClient } from "src/store"
import {
  DeviceLogModel,
  DeviceModel,
  LockerModel,
  LockerLogModel,
  LockerGridsModel,
  DepositOrderList, RendOrderType, RendOrderDetail
} from "types/reserve"

import { get, post, del, put } from "utils";
import { FormInstance } from "antd/lib/form";

export const DEVICE_API = '/Base/device/device'
export const DEVICE_CONFIG_API = '/Lockers/get_config'
export const DEVICE_LINKS_API = '/Base/device/download_links'
export const DEVICE_LOGS_API = '/Base/device/log_list'
// 储物柜api
export const DEVICE_LOCKERS_API = '/Lockers/lockers_list'
export const DEVICE_LOCKERS_LOGS_API = '/Lockers/logs_list'
export const DEVICE_LOCKERS_GRIDS_LIST_API = `/Lockers/grids_list`
export const DEVICE_LOCKERS_CONFIG_API = '/Lockers/set_config'
// 押金订单列表
export const DEVICE_LOCKERS_DEPOSIT_API = '/Lockers/depositOrderList'

export default {
  // 获取config
  config: () => get(DEVICE_CONFIG_API),
  // 储物柜列表
  lockerList: (payload) => get<DataList<LockerModel>>(DEVICE_LOCKERS_API, payload),
  // 添加储物柜
  create: (payload) => post<any>('/Lockers/lockers_add', payload),
  // 编辑储物柜
  update: (payload) => post<any>(`/Lockers/lockers_edit/${payload.id}`, payload),
  // 删除储物柜
  deleteLockers: (payload) => post(`/Lockers/lockers_delete/${payload.id}`, payload),
  // 储物柜格子列表
  gridsList: (id) => get<DataList<LockerGridsModel>>(`${DEVICE_LOCKERS_GRIDS_LIST_API}/${id}`),
  // 储物柜开锁
  gridsUnlock: (payload) => post(`/Lockers/grids_unlock`, {ids: payload}),
  // 储物柜日志
  lockerLogs: (payload) => get<DataList<LockerLogModel>>(`${DEVICE_LOCKERS_LOGS_API}/${payload.lockerId}`, payload),
  // 押金订单列表
  depositOrderList: (payload) => get<DataList<DepositOrderList>>(DEVICE_LOCKERS_DEPOSIT_API, payload),
  // 押金退款
  executeDepositRefund: (payload) => post('/Lockers/executeDepositRefund', payload),
  // 订单列表
  orderLists: (payload) => get<DataList<RendOrderType>>('/Lockers/order_lists', payload),
  // 订单详情
  rentOrderDetail: (id) => get<RendOrderDetail>(`/Lockers/order_detail/${id}`)
}

export async function setParameters(params: { type: 'door' | 'light', authorizeForm: AntdForm, configForm: AntdForm }) {
  const { type, authorizeForm, configForm } = params
  let authorizeValue = authorizeForm.getFieldsValue()
  delete authorizeValue.access_token_expiration
  delete authorizeValue.refresh_token_expiration
  try {
    const payload = {
      ...authorizeValue,
      ...configForm.getFieldsValue()
    }
    await post('/Base/device/set_config', payload)
    message.success(`修改${type === 'door' ? '门禁设备' : '灯控设备'}参数成功！`)
  } catch (error) {
    throw error
  }
}

export async function setLockerConfig(rentForm: FormInstance<any>, paymentForm: FormInstance<any>) {
  try {
    const payload = {
      ...rentForm.getFieldsValue(),
      ...paymentForm.getFieldsValue()
    }
    console.log(payload)
    await post(DEVICE_LOCKERS_CONFIG_API, payload)
    message.success(`设置成功！`)
  } catch (error) {
    throw error
  }
}

export async function getParameters(ctx: QueryContext) {
  const [url] = ctx.queryKey
  return get(url)
}

export async function getLightParameters(ctx: QueryContext) {
  const [url, authorizeForm, configForm] = ctx.queryKey

  try {
    const data = await get(url)
    const { access_token_expiration, refresh_token_expiration, switch_early_time,
      switch_delay_time, is_sync_attend, authorized_url
      // @ts-ignore
    } = data.switch

    authorizeForm.setFieldsValue({ access_token_expiration, refresh_token_expiration })
    configForm.setFieldsValue({ switch_early_time, switch_delay_time, is_sync_attend: Number(is_sync_attend) })
    return authorized_url
  } catch (error) {
    throw error
  }
}

export async function deleteDevice(id: number) {
  try {
    await del(`${DEVICE_API}/${id}`)
    queryClient.invalidateQueries(DEVICE_API)
  } catch (error) {
    throw error
  }
}

export function getDeviceList(ctx: QueryContext) {
  const [url, type, page, page_size] = ctx.queryKey
  return get<DataList<DeviceModel>>(url, { page, page_size, type })
}

export async function submitDevice(params: { id: number | undefined, form: AntdForm }) {
  const { id, form } = params

  try {
    const payload = await form.validateFields()
    payload.resources = (payload.resources || []).flat().filter((value: any) => typeof value === 'number')
    if (id !== undefined) {  // 更新门禁设备
      await put<any>(`${DEVICE_API}/${id}`, payload)
      message.success('修改门禁设备成功！')
    } else {  // 创建门禁设备
      await post(DEVICE_API, payload)
      message.success('创建门禁设备成功！')
    }
    queryClient.invalidateQueries(DEVICE_API)
  } catch (error) {
    error.errorFields && message.error('请正确填写表单！')
    throw error
  }
}

export function getDeviceLogList(ctx: QueryContext) {
  const [url, page, page_size, device_id, type] = ctx.queryKey
  return get<DataList<DeviceLogModel>>(url, { page, page_size, device_id, type })
}

/**
 * 储物柜管理列表
 * @param ctx
 */
export function getLockersList(ctx: QueryContext) {
  const [url, page, page_size] = ctx.queryKey
  return get<DataList<LockerModel>>(url, {page, page_size})
}

export function openLock(ctx: QueryContext) {
  const [ids] = ctx.queryKey
  return post<DataList<LockerLogModel>>('/Base/device/grids_unlock', { ids })
}

export async function useLockerConfig(rentForm, paymentForm, type: 'get' | 'set' = 'set') {
  try {
    if (type === 'set') {
      const payload = {
        ...rentForm.getFieldsValue(),
        ...paymentForm.getFieldsValue()
      }
      await post(DEVICE_LOCKERS_CONFIG_API, payload)
      message.success('设置成功')
    } else {

    }
  } catch (e) {
    throw e
  }
}

