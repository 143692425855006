/*
 * @Author: your name
 * @Date: 2021-01-21 18:19:33
 * @LastEditTime: 2021-03-21 18:42:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Mall/View/Swiper.tsx
 */
import { Block, Header } from 'components'
import { Button, message, Input, Popconfirm, Select } from "antd"
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { mall } from 'services'
import { SwiperImage, SwiperImageBox, SwiperImageMark, Title, UploadBody, UploadText } from './style'
import { SwiperItem } from 'services/mall'
import OSSUpload from 'components/OssUpload'
import { CloudUploadOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import "./style.css"

const Swiper: FC = () => {

  const { Option } = Select
  const [loading, setLoading] = useState<boolean>(true)
  const [imageList, setImageList] = useState<SwiperItem[]>([])
  const [render, setRender] = useState<number>(1)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const data = await mall.getSwiper()
      setImageList(data)
      setLoading(false)
    })()
  }, [render])

  const handleClickNew = useCallback(async (img_url) => {
    try {
      setLoading(true)
      await mall.addSwiper({ img_url, to_url: "http://temp" })
      message.success("添加成功")
      setRender(render + 1)
    } catch (err) {
      message.error(err.message)
    } finally {
      setLoading(false)
    }
  }, [render])

  const handleClickEdit = useCallback(async () => {
    setLoading(true)
    for (let index = 0; index < imageList.length; index++) {
      await mall.editSwiper(imageList[index].id.toString(), { img_url: imageList[index].img_url, to_url: imageList[index].to_url })
    }
    setLoading(false)
    setRender(render + 1)
  }, [imageList, render])

  const handleRenderUrl = useCallback((_url) => {
    let url = _url
    console.log(url)
    url = url.replace("https://", "")
    url = url.replace("http://", "")
    return url
  }, [])

  const handleRenderSelect = useCallback((_url) => {
    let url = _url
    return `http${url[4] === "s" ? "s" : ""}://`
  }, [])

  const handleChangeUrl = useCallback((value, _url) => {
    const url = _url.split("://")[1]
    return `http${value[4] === "s" ? "s" : ""}://${url}`
  }, [])

  return (
    <Fragment>
      <Header
        title="商品分组页展示配置"
        description="本页面的修改会改变商城轮播图的内容，以及可以配置轮播图的跳转链接"
      />
      <Block
        title="轮播图修改"
        description={<Button type="primary" disabled={loading} loading={loading} onClick={handleClickEdit}>保存</Button>}
        loading={loading}
        style={{ paddingBottom: 150, overflow: "hidden" }}

      >
        <Title style={{ marginBottom: 16 }}>选择上传图片</Title>
        {imageList.length < 5 ?
          <div style={{ float: "left" }}>
            <OSSUpload
              fullpath
              showUploadList={false}
              listType="picture-card"
              crop
              cropAspect={2}
              onChange={handleClickNew}
            >
              <UploadBody>
                <PlusOutlined style={{ fontSize: 30, color: "#666" }} />
                <UploadText>上传图片</UploadText>
              </UploadBody>
            </OSSUpload>
          </div>
          : null
        }
        <div style={{ overflow: "hidden" }}>
          {
            imageList && imageList.length ? imageList.map((item, index) =>
              <div style={{ float: "left", marginRight: 15 }}>
                <SwiperImageBox key={item.id} >
                  <SwiperImage src={item.img_url} />
                  <SwiperImageMark>
                    <OSSUpload
                      showUploadList={false}
                      fullpath
                      crop
                      cropAspect={2}
                      onChange={async (url) => {
                        await mall.editSwiper(item.id.toString(), { img_url: url, to_url: item.to_url })
                        setRender(render + 1)
                      }}
                      style={{ border: "none" }}
                    >
                      <CloudUploadOutlined style={{ color: "white", marginRight: 8, display: "block", fontSize: 16, cursor: "pointer" }} />
                    </OSSUpload>
                  |
                  <Popconfirm
                      title="删除操作不可逆,是否继续"
                      onConfirm={async () => {
                        await mall.delSwiper(item.id.toString())
                        setRender(render + 1)
                      }}
                      okText="删除"
                      cancelText="取消"
                    >
                      <DeleteOutlined
                        style={{ color: "white", marginLeft: 8, fontSize: 16 }}
                      />
                    </Popconfirm>
                  </SwiperImageMark>
                </SwiperImageBox>
                <div style={{ textAlign: "center" }}>图片{index + 1}</div>
              </div>
            ) : null
          }
        </div>

        <div style={{ marginTop: 32 }}>
          <b>轮播图跳转链接</b>
          {
            imageList && imageList.length ? imageList.map((item, index) =>
              <div style={{ display: "flex", alignItems: "center", marginTop: 24 }}>
                <b>图片链接{index + 1}:</b>
                <Input
                  value={handleRenderUrl(item.to_url)}
                  addonBefore={
                    <Select
                      value={handleRenderSelect(item.to_url)}
                      onChange={(value) => {
                        const data = JSON.parse(JSON.stringify(imageList))
                        data[index].to_url = handleChangeUrl(value, item.to_url)
                        setImageList(data)
                      }}
                      className="select-before"
                    >
                      <Option value="http://">http://</Option>
                      <Option value="https://">https://</Option>
                    </Select>
                  }
                  onChange={(e) => {
                    const data = JSON.parse(JSON.stringify(imageList))
                    data[index].to_url = handleRenderSelect(item.to_url) + e.target.value
                    setImageList(data)
                  }}
                  style={{ width: 294, marginLeft: 8 }}
                />
              </div>
            ) : null
          }
        </div>
      </Block>
    </Fragment >
  )
}

export default Swiper