/*
 * @Author: your name
 * @Date: 2021-01-21 18:40:25
 * @LastEditTime: 2021-05-14 15:56:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Mall/View/Add.tsx
 */
import { Button, Checkbox, DatePicker, Input, message, Popconfirm, Radio, Select, Steps } from "antd"
import { Block, InputBox } from "components"
import React, { FC, useCallback, useEffect, useState } from 'react'
import mall, { AddMallPayload, MallGroupListResult } from "services/mall"
import MallTypeCard from "../Model/MallTypeCard"
import BraftEditor from 'braft-editor'
import { AddBody, AddDescription, AddImage, AddImageEdit, AddImageList, AddRadio, StepDescription } from "./style"
import { useHistory, useParams } from 'react-router'
import moment from "moment"
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons"
import axios from "axios"
import { getRandomString } from "utils/common"
import { OSS_SIGN } from "consts/url"
import OSSUpload from "components/OssUpload"

interface Config {
  accessid: string,
  host: string,
  policy: string,
  signature: string,
  expire: number,
  callback: string,
  dir: string
}

const Add: FC = () => {
  const history = useHistory()
  const { Step } = Steps
  const params = useParams<{ id?: string }>()
  const [data, setData] = useState<any>()
  const [current, setCurrent] = useState<number>(0)
  const [groupData, setGroupData] = useState<MallGroupListResult>({ data: [], total: 0 })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [imageList, setImageList] = useState<string[]>([])
  const [detail, setDetail] = useState<any>(BraftEditor.createEditorState(null))

  useEffect(() => {
    (async () => {
      setIsLoading(true)
      const data = await mall.getMallGroupList({ page: 1, limit: 100 })
      setGroupData(data)
      if (params.id) {
        const detail = await mall.getMallDetail({ id: params.id })
        setImageList(detail.pic_carousel.split(","))
        setDetail(BraftEditor.createEditorState(detail.details))
        setData(detail)
      } else {
        setData({
          is_show: 1,
          is_unlimited_stock: 1,
          is_inventory: 0
        })
      }
      setIsLoading(false)
    })()
  }, [])

  const submitMall = useCallback(async () => {
    if (params.id) {
      try {
        setIsLoading(true)
        await mall.editMall(params.id, data!)
        message.success("修改成功")
        history.push("/mall/list")
      } catch (err) {
        message.error(err.message)
      } finally {
        setIsLoading(false)
      }
    } else {
      try {
        setIsLoading(true)
        await mall.addMall(data!)
        message.success("添加成功")
        history.push("/mall/list")
      } catch (err) {
        message.error(err.message)
      } finally {
        setIsLoading(false)
      }
    }
  }, [data])

  const handleClickSubmitPage1 = useCallback(() => {
    if (!data?.name) {
      message.error("请输入商品名称")
      return
    } else if (!data.category_id) {
      message.error("请选择商品分类")
      return
    }
    if (imageList.length) {
      setData({ ...data, pic_carousel: imageList.join(), details: encodeURIComponent(detail.toHTML()) })
    } else {
      setData({ ...data, details: encodeURIComponent(detail.toHTML()) })
    }
    setCurrent(1)
  }, [data, imageList, detail])

  const handleClickSubmitPage2 = useCallback(() => {
    if (data?.price === void 0) {
      message.error("请输填写价格")
      return
    }
    if ((data.inventory === void 0) && !data.is_unlimited_stock) {
      message.error("请输填写库存,或选择不限制库存")
      return
    }
    if (data.is_inventory === void 0) {
      message.error("请填写库存扣减方式")
      return
    }
    setCurrent(2)
  }, [data])

  const handleUpload = useCallback(async (e) => {
    try {
      const { data: ossConfig } = await axios.get<Config>(OSS_SIGN)
      const filename = `oss-${Date.now()}-${getRandomString()}.jpg`
      const formData = new FormData()
      const key = ossConfig.dir + filename
      formData.append('key', key)
      formData.append('OSSAccessKeyId', ossConfig.accessid)
      formData.append('policy', ossConfig.policy)
      formData.append('signature', ossConfig.signature)
      formData.append('success_action_status', '200')
      formData.append('callback', ossConfig.callback)
      formData.append('file', e.file)
      await axios.post(ossConfig.host, formData)
      e.success({ url: `${ossConfig.host}/${key}` })
    } catch (err) {
      message.error(err)
    }
  }, [])

  return (
    <Block title={params && params.id ? `编辑${data?.name}` : "添加商品"} loading={isLoading} showBack>
      <Steps current={current} style={{ marginTop: 40, width: 800, margin: "40px auto" }}>
        <Step title="基本信息" description="商品的基本信息设置" />
        <Step title="规格价格" description={<StepDescription>可设置商品的价格,库存等参数</StepDescription>} />
        <Step title="商品周期" description={<StepDescription>可设置商品的上架周期</StepDescription>} />
      </Steps>
      {current === 0 ?
        <AddBody style={{ paddingBottom: 33 }}>
          <div>
            <MallTypeCard
              value={0}
              checked
              title="实体商品"
              description="实体商品主要是零售产品，存在不同规格，颜色等。"
            />
          </div>
          <InputBox title="商品名称" style={{ marginTop: 40 }} must>
            <Input
              style={{ width: 435 }}
              placeholder="请输入商品名称，建议不超过20字"
              value={data?.name}
              onChange={(e) => { setData({ ...data as AddMallPayload, name: e.target.value }) }}
            />
          </InputBox>
          <InputBox title="商品描述" style={{ marginTop: 32 }}>
            <Input
              style={{ width: 435 }}
              placeholder="请输入商品描述，60字左右"
              value={data?.short_description}
              onChange={(e) => { setData({ ...data as AddMallPayload, short_description: e.target.value }) }}
            />
          </InputBox>
          <InputBox
            title="展示图片"
            style={{
              marginTop: 32,
              marginLeft: 45,
              alignItems: "end",
            }}
            must
          >
            <AddImageList>
              {imageList.length < 5 ?
                <div style={{ width: 102, float: "left" }}>
                  <OSSUpload
                    fullpath
                    showUploadList={false}
                    listType="picture-card"
                    crop={false}
                    onChange={url => { setImageList([...imageList, url]) }}
                  />
                </div> : null
              }
              {imageList.map((src: string, index: number) =>
                <AddImage>
                  <img style={{ width: 88, height: 88, position: "absolute", left: 7, top: 7, }} src={src} />
                  <AddImageEdit>
                    <OSSUpload
                      fullpath
                      showUploadList={false}
                      crop={false}
                      onChange={url => {
                        const data = JSON.parse(JSON.stringify(imageList))
                        data[index] = url
                        setImageList(data)
                      }}
                    >
                      <CloudUploadOutlined style={{ color: "white", fontSize: 18, cursor: "pointer" }} />
                    </OSSUpload>
                    <span style={{ color: "white" }}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    <Popconfirm
                      title="删除操作不可逆,是否继续"
                      onConfirm={() => {
                        const data = JSON.parse(JSON.stringify(imageList))
                        data.splice(index, 1)
                        setImageList(data)
                      }}
                      okText="删除"
                      cancelText="取消"
                    >
                      <DeleteOutlined style={{ color: "white", fontSize: 18, cursor: "pointer" }} />
                    </Popconfirm>
                  </AddImageEdit>
                </AddImage>
              )
              }
            </AddImageList>
          </InputBox>
          <InputBox title="商品分组" style={{ marginTop: 32 }} must>
            <Select
              style={{ width: 435 }}
              value={data?.category_id}
              onChange={(value) => { setData({ ...data as AddMallPayload, category_id: value }) }}
              placeholder="请选择"
            >
              {groupData.total ? groupData.data.map(item => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)) : null}
            </Select>
          </InputBox>
          <div style={{
            width: 600,
            marginTop: "24px",
            border: "1px solid rgb(233, 233, 233)"
          }}>
            <BraftEditor
              placeholder="请填写项目预约须知"
              value={detail}
              onChange={(value: any) => setDetail(value)}
              media={{
                accepts: {
                  video: false,
                  audio: false,
                },
                externals: {
                  video: false,
                  audio: false,
                  embed: false,
                },
                pasteImage: true,
                uploadFn: handleUpload
              }}
              controls={[
                'undo', 'redo', 'font-size', 'separator', 'text-color', 'bold', 'italic', 'underline',
                'strike-through', 'separator', 'superscript', 'subscript', 'separator', 'text-indent', 'text-align',
                'separator', 'list-ul', 'list-ol', 'blockquote', 'separator', 'link', 'hr', 'media'
              ]}
            />
          </div>
          <Button type="primary" style={{ marginTop: 32 }} onClick={handleClickSubmitPage1}>下一步</Button>
        </AddBody>
        : null
      }
      {
        current === 1 ?
          <AddBody style={{ paddingBottom: 33 }}>
            <InputBox title="价格" style={{ marginTop: 37, width: 440, marginLeft: 17 }} must>
              &nbsp;¥
            <Input
                type="number"
                value={data?.price}
                style={{ width: 124, marginLeft: 10 }}
                placeholder="请输入商品价格"
                min={0}
                onChange={(e) => { setData({ ...data as AddMallPayload, price: Number(e.target.value) }) }}
              />
            </InputBox>
            <div style={{ marginTop: 37, display: "flex", alignItems: "center", paddingRight: 25 }}>
              <Checkbox
                checked={data.is_show_market_price}
                onChange={(e) => setData({ ...data, is_show_market_price: e.target.checked })}
              />
              <InputBox title="划线价" style={{ width: 456, marginLeft: 10 }}>
                &nbsp;¥
            <Input
                  type="number"
                  defaultValue={data?.market_price}
                  style={{ width: 136, marginLeft: 10 }}
                  placeholder="请输入商品划线价"
                  onChange={(e) => { setData({ ...data as AddMallPayload, market_price: Number(e.target.value) }) }}
                />
              </InputBox>
            </div>
            <AddDescription>商品没有优惠的情况下，划线价在商品详情会以划线形式显示</AddDescription>
            <div style={{ marginTop: 37, display: "flex", alignItems: "center", paddingRight: 24, position: "relative" }}>
              <Checkbox
                checked={data.is_show_exchange_price}
                onChange={(e) => setData({ ...data, is_show_exchange_price: e.target.checked })}
                style={{ left: -55, position: "absolute" }}
              />
              <InputBox title="积分兑换价格" style={{ width: 500, marginLeft: -30 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ float: "left" }}>&nbsp;¥</div>
                  <Input
                    type="number"
                    defaultValue={data?.exchange_price}
                    style={{ width: 136, marginLeft: 10, float: "left" }}
                    placeholder="请输入"
                    onChange={(e) => { setData({ ...data as AddMallPayload, exchange_price: Number(e.target.value) }) }}
                  />
                  <div style={{ padding: "0 8px", float: "left" }}>+</div>
                  <Input
                    type="number"
                    defaultValue={data?.exchange_fraction}
                    style={{ width: 136, float: "left" }}
                    placeholder="请输入"
                    onChange={(e) => { setData({ ...data as AddMallPayload, exchange_fraction: Number(e.target.value) }) }}
                  />&nbsp;积分
              </div>
              </InputBox>
            </div>
            <InputBox title="库存" style={{ marginTop: 32, width: 425 }} must>
              <Input
                type="number"
                value={data?.inventory}
                style={{ width: 124, marginLeft: 8 }}
                placeholder="请输入库存数量"
                onChange={(e) => { setData({ ...data as AddMallPayload, inventory: Number(e.target.value) }) }}
                min={0}
                disabled={Boolean(data?.is_unlimited_stock)}
              />
              <Checkbox
                style={{ marginLeft: 15 }}
                defaultChecked={Boolean(data?.is_unlimited_stock)}
                value={Boolean(data?.is_unlimited_stock)}
                onChange={(e) => { setData({ ...data as AddMallPayload, is_unlimited_stock: Number(e.target.checked) }) }}
              >
                不限库存
            </Checkbox>
              <Checkbox
                value={Number(data?.is_no_show_inventory)}
                onChange={(e) => { setData({ ...data as AddMallPayload, is_no_show_inventory: Number(e.target.checked) }) }}
              >
                不显示库存
            </Checkbox>
            </InputBox>
            <InputBox title="库存扣减方式" style={{ marginTop: 37, width: 555, alignItems: "start" }} must>
              <Radio.Group
                value={data?.is_inventory}
                style={{ marginLeft: 8 }}
                onChange={(e) => { setData({ ...data as AddMallPayload, is_inventory: e.target.value }) }}
              >
                <Radio style={{ display: "block" }} value={0}>
                  拍下减库存
                  <AddRadio>生成订单的时候则扣减库存数量</AddRadio>
                </Radio>
                <Radio style={{ display: "block" }} value={1}>
                  支付减库存
                  <AddRadio>当订单支付成功时才扣减库存数量</AddRadio>
                </Radio>
              </Radio.Group>
            </InputBox>
            <div style={{ marginTop: 32, width: 325 }}>
              <Button onClick={() => { setCurrent(0) }}>上一步</Button>
              <Button type="primary" style={{ marginLeft: 16 }} onClick={handleClickSubmitPage2}>下一步</Button>
            </div>
          </AddBody>
          : null
      }
      {
        current === 2 ?
          <AddBody style={{ paddingBottom: 33 }}>
            <InputBox title="开售时间" style={{ marginTop: 37, width: 470, alignItems: "start" }} must>
              <Radio.Group
                value={data?.is_show}
                style={{ marginLeft: 8 }}
                onChange={(e) => { setData({ ...data as AddMallPayload, is_show: e.target.value }) }}
              >
                <Radio style={{ display: "block" }} value={1}>
                  立即开售
              </Radio>
                <Radio style={{ display: "block", marginTop: 15 }} value={2}>
                  定时开售
                <DatePicker
                    showTime
                    value={data && data.timing ? moment(new Date(data.timing)) : void 0}
                    format="YYYY-MM-DD HH:mm"
                    style={{ marginLeft: 15 }}
                    onChange={(_moment, _string) => { setData({ ...data as AddMallPayload, timing: `${_string}:00` }) }}
                    placeholder="选择开售时间"
                  />
                </Radio>
                <Radio style={{ display: "block", marginTop: 15 }} value={0}>
                  暂不销售
                <AddRadio>&nbsp;&nbsp;放入已下架列表中</AddRadio>
                </Radio>
              </Radio.Group>
            </InputBox>
            <div style={{ marginTop: 32 }}>
              <Button onClick={() => { setCurrent(1) }}>上一步</Button>
              <Button type="primary" style={{ marginLeft: 16 }} onClick={() => { submitMall() }}>提交</Button>
            </div>
          </AddBody>
          : null
      }
    </Block >
  )
}

export default Add