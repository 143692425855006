import { message } from "antd"
import { useState, useRef, useEffect, useCallback } from "react"
import debounce from 'lodash/debounce';

type UseBoolean = [boolean, {
  on: () => void;
  toggle: () => void;
  off: () => void;
}]

interface DebounceOptions {
  // 超时时间，单位为毫秒
  wait?: number;
  // 是否在上升沿触发副作用函数
  leading?: boolean;
  // 是否在下降沿触发副作用函数
  trailing?: boolean;
}

export type noop = (...args: any[]) => any;
type Fn = (...args: any) => any;


export function isFunction(obj: any): obj is Function {
  return typeof obj === 'function';
}

/**
 * Inspired by `chakra-ui`.
 * @see https://chakra-ui.com/docs/hooks/use-boolean
 * @param defaultVal 
 * @returns 
 */
export function useBoolean(defaultVal = false): UseBoolean {
  const [value, setValue] = useState<boolean>(defaultVal)

  return [value, {
    on: () => setValue(true),
    toggle: () => setValue(!value),
    off: () => setValue(false)
  }]
}

export function useClipboard(content?: string) {
  return (_content?: string) => {
    const element = document.createElement('input')
    element.value = _content || content
    document.body.appendChild(element)
    element.select()
    document.execCommand('copy')
    document.body.removeChild(element)
    message.info('已拷贝到剪切版！')
  }
}


/**
 * 持久化 function 的 Hook
 * @see https://ahooks.js.org/zh-CN/hooks/advanced/use-persist-fn
 * @param fn 需要持久化的函数
 * @returns fn 引用地址永远不会变化的 fn
 */
export function usePersistFn<T extends noop>(fn: T) {
  const fnRef = useRef<T>(fn);
  fnRef.current = fn;

  const persistFn = useRef<T>();
  if (!persistFn.current) {
    persistFn.current = function (...args) {
      return fnRef.current!.apply(this, args);
    } as T;
  }

  return persistFn.current!;
}

/**
 * 只在组件 unmount 时执行的 hook。
 * @param fn unmount时执行的函数
 */
export const useUnmount = (fn: any) => {
  const fnPersist = usePersistFn(fn);

  useEffect(
    () => () => {
      if (isFunction(fnPersist)) {
        fnPersist();
      }
    },
    [fnPersist],
  );
};


function depsAreSame(oldDeps: any[], deps: any[]): boolean {
  if (oldDeps === deps) return true;
  for (let i = 0; i < oldDeps.length; i++) {
    if (oldDeps[i] !== deps[i]) return false;
  }
  return true;
}

/**
 * 是 useMemo 或 useRef 的替代品。 避免潜在的性能隐患
 * @see https://ahooks.js.org/zh-CN/hooks/advanced/use-creation
 * @param factory () => any 用来创建所需对象的函数
 * @param deps 	any[] 传入依赖变化的对象
 * @returns 
 */
export function useCreation<T>(factory: () => T, deps: any[]) {
  const { current } = useRef({
    deps,
    obj: undefined as undefined | T,
    initialized: false,
  });
  if (!current.initialized || !depsAreSame(current.deps, deps)) {
    current.deps = deps;
    current.obj = factory();
    current.initialized = true;
  }
  return current.obj as T;
}

/**
 * 用来处理防抖的 Hook
 * @see https://ahooks.js.org/zh-CN/hooks/side-effect/use-debounce-fn
 * @param fn 需要防抖执行的函数
 * @param options 配置防抖的行为
 * @returns {} 
 * {
 *    run: 触发执行 fn，函数参数将会传递给 fn
 *    cancel: 取消当前防抖
 *    flush: 当前防抖立即调用   
 * }
 */
export function useDebounceFn<T extends Fn>(fn: T, options?: DebounceOptions) {
  const fnRef = useRef<T>(fn);
  fnRef.current = fn;

  const wait = options?.wait ?? 1000;

  const debounced = useCreation(
    () =>
      debounce<T>(
        ((...args: any[]) => {
          return fnRef.current(...args);
        }) as T,
        wait,
        options,
      ),
    [],
  );

  useUnmount(() => {
    debounced.cancel();
  });

  return {
    run: (debounced as unknown) as T,
    cancel: debounced.cancel,
    flush: debounced.flush,
  };
}

/**
 * 强制组件重新渲染的 hook
 * @returns void
 */
export function useUpdate() {
  const [, setState] = useState({});

  return useCallback(() => setState({}), []);
}

/**
 * 用于给一个异步函数增加竞态锁，防止并发执行
 * @param fn 异步函数
 */
 export function useLockFn<P extends any[] = any[], V extends any = any>(fn: (...args: P) => Promise<V>) {
  const lockRef = useRef(false);

  return useCallback(
      async (...args: P) => {
        if (lockRef.current) return;
        lockRef.current = true;
        try {
          const ret = await fn(...args);
          lockRef.current = false;
          return ret;
        } catch (e) {
          lockRef.current = false;
          throw e;
        }
      },
      [fn],
  );
}