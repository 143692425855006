/*
 * @Author: your name
 * @Date: 2021-01-14 11:45:45
 * @LastEditTime: 2021-04-06 17:18:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/mall.ts
 */

/*
 * @Author: your name
 * @Date: 2021-01-13 14:25:16
 * @LastEditTime: 2021-01-13 18:25:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/mall.ts
 */
export const groupColumns = [{
  key: 'name',
  dataIndex: 'name',
  title: '商品名称'
}, {
  key: 'state',
  dataIndex: 'state',
  title: '是否显示'
}, {
  key: 'handle',
  dataIndex: 'handle',
  title: '操作'
}]

export const mallColumns = [{
  key: 'name',
  dataIndex: 'name',
  title: '商品名称',
  fixed: 'left' as 'left' | 'right',
  width: 330,
  className: "mall-row"
}, {
  key: 'market_price',
  dataIndex: 'market_price',
  title: '商品原价',
  width: 160,
  className: "mall-row"
}, {
  key: 'inventory',
  dataIndex: 'inventory',
  title: '库存',
  width: 160,
  className: "mall-row"
}, {
  key: 'sales',
  dataIndex: 'sales',
  title: '销量',
  width: 160,
  className: "mall-row"
}, {
  key: 'created_at',
  dataIndex: 'created_at',
  title: '创建时间',
  width: 210,
  className: "mall-row"
}, {
  key: 'is_show',
  dataIndex: 'is_show',
  title: '项目状态',
  width: 200,
  className: "mall-row"
}, {
  key: 'handle',
  dataIndex: 'handle',
  title: '操作',
  fixed: 'right' as 'left' | 'right',
  width: 210,
  align: 'center' as 'center',
  className: "mall-row"
}]

export const orderColumns1 = [{
  key: 'order_number',
  dataIndex: 'order_number',
  title: '订单号',
  width: 275,
}, {
  key: 'user_name',
  dataIndex: 'user_name',
  title: '用户名',
  width: 153,
}, {
  key: 'origin_price',
  dataIndex: 'origin_price',
  title: '原价'
}, {
  key: 'current_price',
  dataIndex: 'current_price',
  title: '现价'
}, {
  key: 'income',
  dataIndex: 'income',
  title: '实收'
}, {
  key: 'state',
  dataIndex: 'state',
  title: '状态'
}, {
  key: 'handle',
  dataIndex: 'handle',
  title: '操作',
  width: 180,
}]

export const orderColumns2 = [{
  key: 'name',
  dataIndex: 'name',
  title: '商品'
}, {
  key: 'number',
  dataIndex: 'number',
  title: '数量'
}, {
  key: 'origin_price',
  dataIndex: 'origin_price',
  title: '原价'
}, {
  key: 'current_price',
  dataIndex: 'current_price',
  title: '现价'
}, {
  key: 'income',
  dataIndex: 'income',
  title: '实收'
}]