import React, { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { Button, Tabs, Form } from "antd";
import { Block, Header } from "components";
import Device, { setLockerConfig } from "services/device";
import List from './List'
import Rent from './Rent'
import Payment from './Payment'
import RentOrder from './Order'
import Deposit from './Deposit'
import { useUpdate } from "utils/hooks";

const Lockers: FC = () => {
  const { t } = useTranslation()
  const [rentForm] = Form.useForm()
  const [paymentForm] = Form.useForm()
  const update = useUpdate()
  const [tabKey, setTabKey] = useState<string>('0')
  const [loading, setLoading] = useState<boolean>(false)


  useEffect(() => {
    (async () => {
      // @ts-ignore
      const { lockers } = await Device.config()
      paymentForm.setFieldsValue({ ...lockers.payment_mode })
      rentForm.setFieldsValue({ ...lockers.lease })
    })()
  }, [paymentForm, rentForm])


  return (
    <Fragment>
      <Header
        title={t("page_lockers_title")}
        description={null}
      />
      <Block
        loading={loading}
        bottom={
          (tabKey === '1' || tabKey === '2') ? <Button
            type="primary"
            onClick={async () => {
              await setLockerConfig(rentForm, paymentForm)
            }}
          >{tabKey === '1' ? '发布' : '保存'}</Button> : null}
      >
        <Tabs activeKey={tabKey} onChange={value => setTabKey(value)}>
          <Tabs.TabPane key="0" tabKey="0" tab="储物柜管理">
            <List setLoading={setLoading}/>
          </Tabs.TabPane>
          <Tabs.TabPane key="1" tabKey="1" tab="租用设置">
            <Form
              layout="vertical"
              form={rentForm}
              initialValues={rentForm.getFieldsValue()}
            >
              <Rent rentForm={rentForm}/>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tabKey="2" tab="付费模式">
            <Form
              layout="vertical"
              form={paymentForm}
              requiredMark={false}
              onFieldsChange={() => update()}
              initialValues={paymentForm.getFieldsValue()}
            >
              <Payment paymentForm={paymentForm}/>
            </Form>
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tabKey="3" tab="租用订单">
            <RentOrder/>
          </Tabs.TabPane>
          <Tabs.TabPane key="4" tabKey="4" tab="押金管理">
            <Deposit/>
          </Tabs.TabPane>
        </Tabs>
      </Block>
    </Fragment>
  )
}

export default Lockers