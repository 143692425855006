import React, { useState, FC, useEffect, useCallback } from 'react'
import { ChartResult } from 'services/finance'
import { originData, usedData } from 'data/finance'
import { Body } from './style'
import View from './View'

export interface ChartData {
  type: string,
  value: number,
  color: string,
  percent: number
}

interface ChartModelType {
  data?: ChartResult
}

const ChartModel: FC<ChartModelType> = (props) => {
  const { data } = props
  const [origin, setOrigin] = useState<ChartData[]>(originData)
  const [used, setUsed] = useState<ChartData[]>(usedData)

  //选择颜色
  const getColor = useCallback((type) => {
    switch (type) {
      case '预约消费': return '#FAD337'
      case '商城购买': return '#F2637B'
      case '购买套餐': return '#1890FF'
      case '账户充值': return '#4DCB73'
      case '余额支付': return '#3BA0FF'
      case '套餐支付': return '#36CBCB'
      case '微信支付': return '#975FE4'
      case '线下支付': return '#E8684A'
      default: return '#30A694'
    }
  }, [])

  //处理数据
  const handleData = useCallback((data) => ({
    type: data.type,
    value: data.value / 100,
    percent: data.percent,
    color: getColor(data.type)
  }), [getColor])

  //设置总流水统计
  useEffect(() => {
    if (!data || !data.total || !data.total.length) return
    const result = data.total.map(item => handleData(item))
    setUsed(result)
  }, [data, handleData])

  //设置营业额统计数据
  useEffect(() => {
    if (!data || !data.revenue || !data.revenue.length) return
    const result = data.revenue.map(item => handleData(item))
    setOrigin(result)
  }, [data, handleData])

  return (
    <Body>
      <View title='总流水统计' data={used} description='所统计的金额为商家实际收入的金额（微信支付收到的金额），根据消费类型进行分类：账户充值，商城购买，套餐购买，预约消费' />
      <View title='营业额统计' data={origin} description='此项统计包括的金额为包括套餐核销和储值余额消费产生的虚拟金额，以及微信支付产生的实际营业额' />
    </Body>
  )
}

export default ChartModel