import React, { FC, useCallback } from 'react'
import { utils, read, writeFile } from 'xlsx'
import { Upload, message, Typography, Spin } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { ContainerOutlined } from '@ant-design/icons'

interface ColumnMap {
  [index: string]: {
    name: string,
    default?: any
  }
}

function formatJSON(matrix: AnyObject[], mapping: ColumnMap) {
  const keys = Object.keys(mapping)
  return matrix.map(row => {
    const newRow: AnyObject = {}
    keys.forEach(key => {
      newRow[mapping[key].name] = row[key] || mapping[key].default || null
    })
    return newRow
  })
}

interface Props {
  mapping?: ColumnMap,
  onChange: (matrix: AnyObject[]) => any,
  hasHeader?: boolean,
  parseHeader?: boolean,
  type?: 'card' | 'dragger',
  loading?: boolean,
  showUploadList?: boolean,
  max?: number
}

const XLSX: FC<Props> = (props) => {
  const { loading = false, showUploadList = true } = props

  const beforeUpload = useCallback((file: RcFile) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return
      // 设置时间格式
      const workbook = read(e.target.result, { type: 'binary', cellDates: true, dateNF: 'yyyy/mm/dd' })
      if (workbook.SheetNames.length === 0) message.error('🤥没有发现工作表')
      const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
      // 返回原始数据
      const jsonArr = utils.sheet_to_json(firstWorksheet, {
        raw: false,
        header: "A",
        defval: null,
        dateNF: 'yyyy/mm/dd'
      })
      let mapping: ColumnMap = {}
      if (props.parseHeader) {
        const header = jsonArr.slice(0, 1)[0] as AnyObject
        Object.keys(header).forEach(key => {
          mapping[key] = {
            name: props.mapping?.[key]?.name || header[key],
            default: props.mapping?.[key]?.default || ''
          }
        })
      } else {
        mapping = props.mapping || {}
      }
      const matrix = formatJSON(jsonArr as AnyObject[], mapping)
      props.onChange(props.hasHeader ? matrix.slice(1) : matrix)
    }
    reader.readAsBinaryString(file)
    return false
  }, [props])

  const uploadLayout = useCallback(() => {
    let layout = {
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      beforeUpload: beforeUpload,
      showUploadList: showUploadList,
      maxCount: 0
    }

    if (props.type === 'dragger' && props.max) {
      layout.maxCount = props.max
    }
    return layout
  }, [props.type, props.max])

  return (
    <Spin spinning={loading}>
      {
        props.type === 'dragger' ? (
          <Upload.Dragger
            {...uploadLayout()}
          >
            <Typography>
              <ContainerOutlined style={{ color: "#30A694", fontSize: '30px' }}/>
            </Typography>
            <Typography style={{ margin: '10px auto' }}>
              <Typography.Text strong>点击或将文件拖拽到这里上传</Typography.Text>
            </Typography>
            <Typography>
              <Typography.Text type="secondary">仅支持扩展名 .xlsx</Typography.Text>
            </Typography>
          </Upload.Dragger>
        ) : (
          <Upload
            {...uploadLayout()}
          >
            {props.children}
          </Upload>
        )
      }
    </Spin>
  )
}

export function exportExcel(data: AnyObject<string>[], name: string, header?: string[]) {
  const book = utils.book_new();
  const sheet = utils.json_to_sheet(data, { header })
  utils.book_append_sheet(book, sheet)
  writeFile(book, `${name}.xlsx`)
}

export default XLSX
