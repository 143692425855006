/*
 * @Author: your name
 * @Date: 2021-01-13 10:42:53
 * @LastEditTime: 2021-03-22 14:29:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/services/mall.ts
 */

import { post } from "utils"

const baseURL = '/Shopping/admin/category/'

export default {
  // 获取预约分组
  getMallGroupList: (payload: MallGroupListPayload) => post<MallGroupListResult>(`${baseURL}index`, payload),
  // 新增预约分组
  addMallGroup: (payload: AddMallGroupPayload) => post<null>(`${baseURL}store`, payload),
  // 修改预约分组
  editMallGroup: (payload: EditMallGroupPayload) => post<null>(`${baseURL}update/${payload.id}`, payload),
  // 删除预约分组
  delMallGroup: (payload: DeleteGroupPayload) => post<null>(`${baseURL}delete/${payload.id}`),
  // 获取商品
  getMallList: (payload: MallListPayload) => post<MallListResult>(`/Shopping/admin/goods/index`, payload),
  // 获取商品详情
  getMallDetail: (payload: { id: string }) => post<AddMallPayload>(`/Shopping/admin/goods/show/${payload.id}`),
  // 新增商品
  addMall: (payload: AddMallPayload) => post<null>(`/Shopping/admin/goods/store`, payload),
  // 编辑商品
  editMall: (id: string, payload: AddMallPayload) => post<null>(`/Shopping/admin/goods/update/${id}`, payload),
  // 批量上下架
  editMallState: (payload: EditMallStatePayload) => post<null>(`/Shopping/admin/goods/update_state`, payload),
  // 获取轮播图
  getSwiper: () => post<SwiperItem[]>('/Shopping/admin/banners/index'),
  // 修改轮播图
  editSwiper: (id: string, payload: { img_url: string, to_url: string }) => post<SwiperItem[]>(`/Shopping/admin/banners/update/${id}`, payload),
  // 添加轮播图
  addSwiper: (payload: { img_url: string, to_url: string }) => post<SwiperItem[]>(`/Shopping/admin/banners/store`, payload),
  // 删除轮播图
  delSwiper: (id: string) => post<SwiperItem[]>(`/Shopping/admin/banners/delete/${id}`),
}


export interface SwiperItem {
  id: number;
  to_url: string;
  img_url: string;
}

interface EditMallStatePayload {
  ids: string,
  is_show: number,
}

export interface AddMallPayload {
  name: string,
  category_id: number,
  pic_carousel: string,
  price: number,
  market_price: number,
  inventory: number,
  is_unlimited_stock: number,
  is_no_show_inventory: number,
  short_description: string,
  goods_type: number,
  is_inventory: number,
  is_show: number,
  timing: string,
  details: string,
}
export interface EditMallGroupPayload {
  id: string,
  name: string,
  state: number,
}

interface DeleteGroupPayload {
  id: string
}

export interface AddMallGroupPayload {
  name: string,
  state: number,
}

interface MallGroupListPayload {
  page: number,
  limit: number,
}

export interface MallGroupListResult {
  data: MallGroup[]
  total: number
}

interface MallGroup {
  id: number;
  reserve_system_id: number;
  name: string;
  pid: number;
  sort: number;
  state: number;
  created_at: string;
  updated_at: string;
  deleted_at?: any;
}

interface MallListPayload {
  page: number,
  limit: number,
  type: number,
  goods_name?: string,
  category_id?: number,
  min_sales?: number,
  max_sales?: number,
  min_price?: number,
  max_price?: number,
}

interface MallListResult {
  data: Mall[]
  total: number
}

export interface Mall {
  id: number;
  reserve_system_id: number;
  name: string;
  goods_type: number;
  category_id: number;
  inventory: number;
  market_price: string;
  price: string;
  sales: number;
  pic_cover: string;
  pic_carousel: string;
  short_description: string;
  is_show: number;
  created_at: string;
  timing: string,
}