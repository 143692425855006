/*
 * @Author: your name
 * @Date: 2021-04-06 14:11:27
 * @LastEditTime: 2021-04-06 17:26:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Mall/View/Order.tsx
 */
import React, { FC, useEffect, useState, useCallback, Fragment } from 'react'
import { Block, InputBox } from 'components'
import { Tabs, Select, Button, Input, Table } from 'antd'
import { ConfigureResult, ActionPayload, Order } from 'services/order'
import { Header, Title, Nav } from 'src/pages/Order/View/style'
import { orderColumns1, orderColumns2 } from 'consts/mall'
import "./style.css"

const List: FC<{ configure?: ConfigureResult }> = (props) => {
  const { TabPane } = Tabs
  const { Option } = Select
  const { Search } = Input
  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")
  const [selectRowIdList, setSelectRowIdList] = useState<React.ReactText[]>([])
  const [total, setTotal] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [tab, setTab] = useState<string>("全部订单")
  const [option, setOption] = useState<string>("全部订单")
  const [data, setData] = useState<any[]>([{
    order_number: "1454",
    user_name: "w123123",
    phone: "1254646164",
    create_time: "2017-01-01 08:20:00",
    origin_price: "142",
    order_list: [{
      name: "商品1",
      number: 1
    }]
  }])

  return <Fragment>
    <Block
      loading={loading}
      style={{
        paddingTop: '32px'
      }}>
      <Tabs
        activeKey={tab}
        type="card"
        style={{ borderBottom: 'none' }}
        onChange={(key) => {
          setTab(key)
          if (key === "全部订单") {
            setOption("全部订单")
          }
          if (key === "待支付") {
            setOption("待支付")
          }
          if (key === "待发货") {
            setOption("待发货")
          }
          if (key === "已发货") {
            setOption("已发货")
          }
          if (key === "已完成") {
            setOption("已完成")
          }
          setSelectRowIdList([])
          setPage(1)
        }}
      >
        <TabPane key="全部订单" tab="全部订单" />
        <TabPane key="待支付" tab="待支付" />
        <TabPane key="待发货" tab="待发货" />
        <TabPane key="已发货" tab="已发货" />
        <TabPane key="已完成" tab="已完成" />
      </Tabs>
      <Header>
        <Title>订单列表</Title>
        <Nav>
          <Select
            value={option}
            style={{ width: 200 }}
            placeholder=''
            onChange={(value) => {
              setOption(value)
              setSelectRowIdList([])
              setPage(1)
            }}
          >
            {
              tab === "全部订单" ? <Fragment>
                <Option value="全部订单">全部订单</Option>
                <Option value="待支付">待支付</Option>
                <Option value="待发货">待发货</Option>
                <Option value="已发货">已发货</Option>
                <Option value="已完成">已完成</Option>
                <Option value="已关闭">已关闭</Option>
              </Fragment> : null
            }
            {
              tab === "待支付" ? <Fragment>
                <Option value="待支付">待支付</Option>
              </Fragment> : null
            }
            {
              tab === "待发货" ? <Fragment>
                <Option value="待发货">待发货</Option>
              </Fragment> : null
            }
            {
              tab === "已发货" ? <Fragment>
                <Option value="已发货">已发货</Option>
              </Fragment> : null
            }
            {
              tab === "已完成" ? <Fragment>
                <Option value="已完成">已完成</Option>
              </Fragment> : null
            }
          </Select>
          <Search
            placeholder="请输入订单号/用户名/手机号"
            onSearch={value => setSearch(value)}
            style={{ width: 320, marginLeft: 8 }}
          />
          <Button type='primary' style={{ marginLeft: 8 }} onClick={() => { }}>导出EXCEL</Button>
        </Nav>
      </Header>
      <Table
        columns={orderColumns1}
        dataSource={data?.map(item => ({
          order_number: <div>
            <div>{item.order_number}</div>
            <div>
              <InputBox title="下单时间">{item.create_time}</InputBox>
            </div>
          </div>,
          user_name: <div>
            <div>{item.user_name}</div>
            <div>{item.phone}</div>
          </div>,
          origin_price: <div className="temp-row111213123">{item.origin_price}</div>,
          current_price: <div className="temp-row111213123">{item.current_price}</div>,
          income: <div className="temp-row111213123">{item.income}</div>,
          state: <div className="temp-row111213123">{item.origin_price}</div>,
          handle: <div className="temp-row111213123">
            <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">查看详情</Button>
            <span style={{ color: "#30A694" }}>&nbsp;|&nbsp;</span>
            <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">操作</Button>
          </div>,
          order_list: item.order_list
        }))
        }
        expandable={{
          expandedRowRender: data => <Table
            columns={orderColumns2}
            dataSource={data.order_list}
            pagination={false}
          />
        }}
        rowSelection={{
          selectedRowKeys: selectRowIdList,
          onChange: selectedRowKeys => { setSelectRowIdList(selectedRowKeys) }
        }}
        pagination={{
          total: total,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize,
          pageSizeOptions: ['10', '20', '50', '100'],
          onChange: (_page, _pageSize) => {
            if (_page !== page) setPage(_page)
            if (_pageSize !== pageSize) setPageSize(_pageSize!)
          },
        }}
        style={{
          marginTop: 24
        }}
      />
    </Block >
  </Fragment>
}

export default List