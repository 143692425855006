import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Block, Header, Icon } from "components";
import { useSearchParams } from "utils/common";
import { Card, Checkbox, Col, Row, Typography, message, Button, Modal, Divider } from "antd";
import device from "services/device";
import { LockerGridsModel } from "types/reserve";
import styles from './common.module.css'
import { useBoolean } from "utils/hooks";
import { useTranslation } from "react-i18next";

const Unlock: FC<{
  visible: boolean, success: boolean, locks: LockerGridsModel[], setVisible: {
    on: () => void,
    toggle: () => void,
    off: () => void
  }
}> = (props) => {
  const { visible, setVisible, success, locks } = props

  return (
    <Modal
      visible={visible}
      onCancel={setVisible.off}
      footer={null}
    >
      <Divider/>
      <Row gutter={[10, 10]} style={{ textAlign: 'center', marginTop: 20 }}>
        <Col span={24}>
          <div className={styles.unlock}>
            <Icon size={35} color="#30A694" type="iconsuomimakaisuojiesuo"/>
          </div>
        </Col>
        {
          success ? <Fragment>
            <Col style={{ marginTop: 20 }} span={24}><Typography.Title level={4}>开锁成功</Typography.Title></Col>
            <Col span={24}><Typography.Text type="secondary">使用后请随手关闭储物柜</Typography.Text></Col>
            <Col span={24} style={{ textAlign: 'center', paddingBottom: 10 }}>
              <Button onClick={setVisible.off}>我知道了</Button>
            </Col>
          </Fragment> : <Fragment>
            <Col style={{ marginTop: 20 }} span={24}><Typography.Title level={4}>开锁中</Typography.Title></Col>
            <Col span={24}><Typography.Text
              type="secondary"> {locks?.map(i => i.grids_number + '储物柜')?.join(',')} 正在开锁，请耐心等待……</Typography.Text></Col>
            <Col span={24} style={{ textAlign: 'center', paddingBottom: 10 }}>
              <Button>取消开锁</Button>
            </Col>
          </Fragment>
        }
      </Row>
    </Modal>
  )
}

const Control: FC = () => {
  const { t } = useTranslation()
  const { id, name, num } = useSearchParams()
  const [ids, setIds] = useState<any[]>([])
  const [allBox, setAllBox] = useState<LockerGridsModel[]>([])
  const [visible, setVisible] = useBoolean(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [locks, setLocks] = useState<any[]>([])

  useEffect(() => {
    (async () => {
      try {
        const data = await device.gridsList(id)
        setAllBox(data.list)
      } catch (e) {
        message.error('未知错误')
        setAllBox(Array.from({ length: num }, (_, k) => ({ id: k, grids_number: k })))
      }
    })()
  }, [id])

  useEffect(() => setSuccess(false), [visible])

  const batch = useCallback(async (id: LockerGridsModel[]) => {
    try {
      setLocks(id)
      setVisible.on()
      await device.gridsUnlock(id.map(i => i.id))
      setSuccess(true)
      setLocks([])
    } catch (e) {
      message.error(e.data.message || e.data || e.message || e)
      setLocks([])
      setVisible.off()
    }
  }, [setVisible])

  return (
    <Fragment>
      <Header
        title={t("page_lockers_title")}
        description={null}
      />
      <Block title={`${name}管理`} showBack>
        <Row gutter={[20, 20]} style={{ marginTop: '20px' }} align={'middle'}>
          <Col>
            <Button type={'primary'} onClick={() => batch(ids)}>开锁</Button>
          </Col>
          <Col>
            <Checkbox
              checked={(ids.length === allBox.length) && ids.length !== 0}
              onChange={({ target: { checked } }) => {
                checked ? setIds([...allBox]) : setIds([])
              }}>全选</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox.Group value={ids} style={{ width: '100%' }} onChange={(checkedValue) => setIds(checkedValue)}>
              <Row gutter={[20, 20]}>
                {
                  !allBox.length ? null : allBox.map(item => {
                    return (
                      <Col span={4} key={item.id}>
                        <Card
                          actions={[
                            <div key='kaisuo' onClick={async () => batch([item])}>开锁</div>,
                          ]}
                          bodyStyle={{
                            boxSizing: 'border-box',
                            padding: 0,
                            backgroundColor: ids.includes(item) ? '#E3F3EE' : void 0,
                            border: ids.includes(item.id) ? '1px solid #30A694' : void 0,
                            transition: 'all 300ms ease-in-out 0s'
                          }}
                        >
                          <div style={{ padding: 24 }}>
                            <Card.Meta
                              title={<Checkbox style={{ float: "right" }} value={item}/>}
                              description={<Typography.Title style={{ textAlign: 'center', color: '#30A694' }}
                                                             level={3}>{item.grids_number}</Typography.Title>}
                            />
                          </div>
                        </Card>
                      </Col>
                    )
                  })
                }
              </Row>
            </Checkbox.Group>
          </Col>
        </Row>
        <Unlock visible={visible} setVisible={setVisible} success={success} locks={locks}/>
      </Block>
    </Fragment>
  )
}

export default Control