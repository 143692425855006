/*
 * @Author: your name
 * @Date: 2021-01-21 18:40:25
 * @LastEditTime: 2021-01-22 15:21:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Mall/Model/MallTypeCard/index.tsx
 */
import { Radio, Steps } from "antd"
import { Block, InputBox } from "components"
import React, { FC } from 'react'
import { Body, Description, Title } from "./style"

interface Type {
  value: number
  checked: boolean,
  title: string,
  description: string,
}

const MallTypeCard: FC<Type> = (props) => {

  return (
    <Body style={{ color: props.checked ? "#30A694" : "#333", border: props.checked ? "1px solid #30A694" : "1px solid #E9E9E9" }}>
      <Radio checked style={{ position: "absolute", right: 15, top: 15 }} />
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
    </Body>
  )
}

export default MallTypeCard