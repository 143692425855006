/*
 * @Author: your name
 * @Date: 2021-01-28 13:47:50
 * @LastEditTime: 2021-01-29 11:05:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/NavBar/index.tsx
 */
import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import { ossURL } from 'consts/url'
import { Body } from './style'
import { CrownOutlined, ShopOutlined } from '@ant-design/icons'

interface MenuProps {
  inlineCollapsed?: boolean
}

interface MenuType {
  key: string,
  title: ReactNode,
  icon?: ReactNode,
  link?: string,
  hidden?: boolean
}

interface MenuList extends MenuType {
  children?: MenuType[]
}

const menuList: MenuList[] = [
  {
    key: 'sub1',
    title: '高级功能',
    icon: <CrownOutlined />,
    children: [
      {
        key: '1-1',
        title: '财务模块',
        link: '/finance/view'
      }
    ]
  },
  {
    key: 'sub2',
    title: '商城配置',
    icon: <ShopOutlined />,
    children: [
      {
        key: '2-1',
        title: '商品分组',
        link: '/mall/group'
      },
      {
        key: '2-2',
        title: '商品列表',
        link: '/mall/list'
      },
      {
        key: '2-3',
        title: '商城配置',
        link: '/mall/swiper'
      }
    ]
  },
  {
    key: 'sub3',
    title: '储物柜',
    icon: <CrownOutlined />,
    link: '/lockers/index'
  },
  {
    key: 'sub3',
    title: '储物柜',
    hidden: true,
    link: '/Control/lockers'
  },
  {
    key: 'sub3',
    title: '储物柜',
    hidden: true,
    link: '/log/lockers'
  },
]

const treeToArray = (tree, result = []) => {
  tree.forEach(node => {
    result.push(node)
    node.children && treeToArray(node.children, result)
  })

  return result
}

const NavBar: FC<MenuProps> = (props) => {
  // const { app: { configure } } = useSelector((state: RootState) => state)
  const [selectMenu] = useState<string>(treeToArray(menuList).find(item => item?.link === window.location.pathname)?.key)
  const { SubMenu } = Menu

  const renderMenu = useCallback((data) => {
    return data.map(item => {
      if (item.hidden) return
      if (item.children) {
        return (
          <SubMenu key={item.key} title={item.title} icon={item.icon}>
            { renderMenu(item.children) }
          </SubMenu>
        )
      }
      return (
        <Menu.Item key={item.key} title={item.title} icon={item.icon}>
          <Link to={item.link}>{item.title}</Link>
        </Menu.Item>
      )
    })
  }, [])

  return (
    <Body>
      <img
        src={`${ossURL}/img/${props.inlineCollapsed ? 'mini_' : ''}logo.png`}
        style={props.inlineCollapsed ?
          { width: '36px', margin: '19px 22px 44px' } :
          { width: '188px', margin: '19px 34px 44px' }
        }
       alt=''
      />
      <Menu
        style={{
          minHeight: '100%',
          borderRight: 'none'
        }}
        mode='inline'
        defaultSelectedKeys={[selectMenu]}
        inlineCollapsed={props.inlineCollapsed}
      >
        {/* <SubMenu key='sub2' title='订单管理' icon={<InsertRowAboveOutlined />}>
          <Menu.Item key='2'>
            <Link to='/order/list'>订单列表</Link>
          </Menu.Item>
        </SubMenu> */}
        {
          renderMenu(menuList)
        }
      </Menu>
    </Body >
  )
}

export default NavBar