import { Block, Header, InputBox } from 'components'
import { Button, Table, message, Drawer, Switch, Input, Tag, Popconfirm } from "antd"
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { mall } from 'services'
import { ButtonBar, Title } from './style'
import { groupColumns } from 'consts/mall'
import { AddMallGroupPayload, EditMallGroupPayload, MallGroupListResult } from 'services/mall'

const Group: FC = () => {

  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(20)
  const [newIsOpen, setNewIsOpen] = useState<boolean>(false)
  const [newPayload, setNewPayload] = useState<AddMallGroupPayload>({ name: "", state: 1 })
  const [editIsOpen, setEditIsOpen] = useState<boolean>(false)
  const [editPayload, setEditPayload] = useState<EditMallGroupPayload>({ id: "", name: "", state: 1 })
  const [groupData, setGroupData] = useState<MallGroupListResult>({ data: [], total: 0 })
  const [render, setRender] = useState<number>(1)

  useEffect(() => {
    (async () => {
      const data = await mall.getMallGroupList({ page, limit })
      setGroupData(data)
    })()
  }, [render])

  useEffect(() => {
    setPage(0)
  }, [limit])

  useEffect(() => {
    setRender(render + 1)
  }, [page])

  const Vac = useCallback((payload) => {
    let result = false
    if (!payload.name) {
      result = true
      message.error("请输入分组名称")
    }
    return result
  }, [])

  const handleClickNew = useCallback(async () => {
    if (Vac(newPayload)) return
    try {
      await mall.addMallGroup(newPayload)
      setNewIsOpen(false)
      message.success("添加成功")
      setNewPayload({ name: "", state: 1 })
      setRender(render + 1)
    } catch (err) {
      message.error("")
    }
  }, [newPayload, render])

  const handleClickEdit = useCallback(async () => {
    console.log(editPayload)
    if (Vac(editPayload)) return
    try {
      await mall.editMallGroup(editPayload)
      setEditIsOpen(false)
      message.success("修改成功")
      setRender(render + 1)
    } catch (err) {
      message.error("")
    }
  }, [editPayload, render])

  const handleClickDelete = useCallback(async (id) => {
    try {
      await mall.delMallGroup({ id })
      message.success("删除成功")
      setRender(render + 1)
    } catch (err) {
      message.error("")
    }
  }, [render])

  const handleListData = useCallback((list) => {
    if (!list || !list.length) return []
    return list.map((item: any) => ({
      name: item.name,
      state: item.state ? <Tag color="green">微信端显示</Tag> : <Tag color="default">微信端不显示</Tag>,
      handle: <div>
        <Button type="link" onClick={() => {
          setEditIsOpen(true)
          setEditPayload({
            id: item.id,
            name: item.name,
            state: item.state
          })
        }}>编辑</Button>
        <Popconfirm
          placement="bottom"
          title={`您要删除${item.name}么`}
          onConfirm={() => handleClickDelete(item.id)}
          okText="确认删除"
          cancelText="暂不操作"
        >
          <Button type="link">删除</Button>
        </Popconfirm>
      </div>
    }))
  }, [])

  return (
    <Fragment>
      <Header
        title="商品分组"
        description="此页面为商品的分组创建页，可为商品创建分组"
      />
      <Block style={{ paddingTop: 0, overflow: "hidden" }}>
        <Title>商品分组列表</Title>
        <ButtonBar>
          <Button type="primary" onClick={() => { setNewIsOpen(true) }}>新建商品分组</Button>
          {/* <Button style={{ marginLeft: 8 }}>导入预约分组</Button> */}
        </ButtonBar>
        <Table
          columns={groupColumns}
          dataSource={handleListData(groupData.data)}
          pagination={{
            total: groupData.total,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSize: limit,
            pageSizeOptions: ['10', '20', '50', '100'],
            onChange: (_current, _size) => {
              if (_current !== page) setPage(_current)
              if (_size !== limit) setLimit(_size!)
            },
          }}
        />
      </Block>
      <Drawer
        visible={newIsOpen}
        title="新建商品分组"
        width={512}
        onClose={() => { setNewIsOpen(false) }}
        bodyStyle={{ paddingTop: 0 }}
        footer={
          <div style={{ float: "right" }}>
            <Button onClick={() => { setNewIsOpen(false) }}>取消</Button>
            <Button type="primary" style={{ marginLeft: 8 }} onClick={handleClickNew}>确定</Button>
          </div>
        }
      >
        {/* <Tabs
          type="line"
          defaultActiveKey="1"
        >
          <Tabs.TabPane tab="基本设置" key="1"> */}
        <InputBox title="展示分组" style={{ marginTop: 20 }}>
          <Switch
            checked={!!newPayload.state}
            checkedChildren="开启"
            unCheckedChildren="关闭"
            onChange={(value) => { setNewPayload({ ...newPayload, state: Number(value) }) }}
          />
        </InputBox>
        <InputBox title="分组名称" style={{ marginTop: 10 }}>
          <Input
            value={newPayload.name}
            style={{ width: 200 }}
            placeholder="请输入分组名称"
            onChange={(e) => { setNewPayload({ ...newPayload, name: e.target.value }) }}
          />
        </InputBox>
        {/* </Tabs.TabPane>
          <Tabs.TabPane tab="高级设置" key="2">

          </Tabs.TabPane>
        </Tabs> */}
      </Drawer>
      <Drawer
        visible={editIsOpen}
        title="修改商品分组"
        width={512}
        onClose={() => { setEditIsOpen(false) }}
        bodyStyle={{ paddingTop: 0 }}
        footer={
          <div style={{ float: "right" }}>
            <Button onClick={() => { setEditIsOpen(false) }}>取消</Button>
            <Button type="primary" style={{ marginLeft: 8 }} onClick={handleClickEdit}>确定</Button>
          </div>
        }
      >
        {/* <Tabs
          type="line"
          defaultActiveKey="1"
        >
          <Tabs.TabPane tab="基本设置" key="1"> */}
        <InputBox title="展示分组" style={{ marginTop: 20 }}>
          <Switch
            checked={!!editPayload.state}
            checkedChildren="开启"
            unCheckedChildren="关闭"
            onChange={(value) => { setEditPayload({ ...editPayload, state: Number(value) }) }}
          />
        </InputBox>
        <InputBox title="分组名称" style={{ marginTop: 10 }}>
          <Input
            value={editPayload.name}
            style={{ width: 200 }}
            placeholder="请输入分组名称"
            onChange={(e) => { setEditPayload({ ...editPayload, name: e.target.value }) }}
          />
        </InputBox>
        {/* </Tabs.TabPane>
          <Tabs.TabPane tab="高级设置" key="2">

          </Tabs.TabPane>
        </Tabs> */}
      </Drawer>
    </Fragment>
  )
}

export default Group