/*
 * @Author: your name
 * @Date: 2021-01-22 17:43:12
 * @LastEditTime: 2021-01-22 17:43:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/utils/common.ts
 */

/**
 * 获取随机字符串
 * @param len 随机字符串长度
 */
export const getRandomString = (len: number = 8): string => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  let pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * 52))
  }
  return pwd
}

export function useSearchParams() {
  const params = new URLSearchParams(window.location.search)
  const searchParams: AnyObject = {}
  const keys = params.keys()
  // @ts-ignore
  for (const key of keys) {
    searchParams[key] = params.get(key)
  }
  return searchParams
}
