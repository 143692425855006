/*
 * @Author: your name
 * @Date: 2021-01-21 17:46:39
 * @LastEditTime: 2021-04-06 14:46:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/pages/Mall/index.tsx
 */

import React, { FC, Fragment } from 'react'
import { Route } from 'react-router-dom'
import List from './View/List'
import Add from './View/Add'
import Group from './View/Group'
import Swiper from './View/Swiper'
import Order from './View/Order'


const Mall: FC = () => {


  return (
    <Fragment>
      <Route path="/mall/list" component={List} />
      <Route path="/mall/add/:id?" component={Add} />
      <Route path="/mall/group" component={Group} />
      <Route path="/mall/swiper" component={Swiper} />
      <Route path="/mall/order" component={Order} />
    </Fragment>
  )
}

export default Mall