import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import {
  Statistic,
  Row,
  Col,
  Table,
  Typography,
  Divider,
  Button,
  Form,
  Input,
  DatePicker,
  Space,
  Tabs,
  message, Popconfirm
} from 'antd';
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDebounceFn } from "utils/hooks";
import device from "services/device";
import { DepositOrderList, Deposit as DepositType } from "types/reserve";
import { exportExcel } from "components/XLSX";

const Deposit: FC = (props) => {
  const [rowSelection, setRowSelection] = useState([])
  const [listIsFetching, setListIsFetching] = useState<boolean>(false)
  const [time, setTime] = useState<string[]>(undefined)
  const [type, setType] = useState<any>('0')
  const [keyword, setKeyword] = useState<string>(undefined)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [total, setTotal] = useState<number>(0)
  const [dataSource, setDataSource] = useState<DepositOrderList[]>([])
  const [deposit, setDeposit] = useState<DepositType>()

  const { run: fetchData } = useDebounceFn((keyword: string, time: string[], page: number, page_size: number, type: string) => {
    device.depositOrderList({
      keyword,
      start_date: time?.length ? time[0] : void 0,
      end_data: time?.length ? time[1] : void 0,
      type,
      page,
      page_size
    }).then(res => {
      setDataSource(res.list);
      setDeposit(res.deposit)
      setTotal(res.total)
    }).finally(() => {
      return setListIsFetching(false)
    })
  }, { wait: 1000 })

  const handleChangeTime = useCallback(async (value: any) => {
    let temp = value === null ? undefined : [
      value[0].format('YYYY-MM-DD'),
      value[1].format('YYYY-MM-DD')
    ]
    setTime(temp)
  }, [])

  useEffect(() => {
    (async () => {
      setListIsFetching(true)
      await fetchData(keyword, time, page, pageSize, type)
    })()
  }, [page, pageSize, keyword, time, type])

  /**
   * 执行退款
   */
  const handleExecuteDepositRefund = useCallback((orderId: string) => {
    message.destroy()
    const loading = message.loading('请求退款中...')
    device.executeDepositRefund({ id: orderId }).then(() => {
      message.success('退款成功')
      fetchData(keyword, time, page, pageSize, type)
    }).catch(e => {
      message.error(e)
    }).finally(() => {
      loading()
    })
  }, [[page, pageSize, keyword, time, type]])

  /**
   * 导出excel
   */
  const handleExportExcel = useCallback(() => {
    if (!rowSelection.length) return message.error('请至少选择一列数据')
    const exportData = dataSource.filter(item => rowSelection.includes(item.id))
    setListIsFetching(true)
    const hide = message.loading('正在导出Excel...')
    const header = ['用户姓名', '联系方式', '押金', '申请退款时间', '缴纳时间']

    try {
      let arr = exportData.map(item => ({
        用户姓名: item.name,
        联系方式: item.phone,
        押金: item.deposit,
        申请退款时间: item.apply_refund_date,
        缴纳时间: item.created_at,
      }))
      // @ts-ignore
      exportExcel(arr, '押金列表数据', header)
      setListIsFetching(false)
      hide()
    } catch (e) {
      console.log(e)
      setListIsFetching(false)
      hide()
    }
  }, [rowSelection, dataSource])

  let columns = [
    {
      title: '用户姓名',
      dataIndex: 'name',
    },
    {
      title: '联系方式',
      dataIndex: 'phone',
    },
    {
      title: '押金',
      dataIndex: 'deposit',
    },
    {
      title: '申请退款时间',
      dataIndex: 'apply_refund_date',
    },
    {
      title: '缴纳时间',
      dataIndex: 'created_at',
    },
    {
      title: '操作',
      render: (_, record) => (
        <Fragment>
          <Popconfirm
            disabled={(record.state !== 2)}
            title={"该操作不可撤销，确定退款吗?"}
            onConfirm={() => handleExecuteDepositRefund(record.id)}
            okText="确认退款"
            cancelText="我点错了"
          >
            {
              record.state === 2 ? <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">退款</Button> : null
            }
          </Popconfirm>
        </Fragment>
      )
    },
  ];

  if (type === '2') {
    columns = columns.filter(i => i.dataIndex !== 'apply_refund_date')
  }

  if (type === '3') {
    columns.pop()
    columns.push({
      title: '退款时间',
      dataIndex: 'updated_at',
    })
  }

  return (
    <Fragment>
      <Row gutter={16} style={{ textAlign: 'center' }} align="middle" justify="center">
        <Col span={24}>
          <Typography.Title level={4} style={{ textAlign: 'start' }}>押金统计</Typography.Title>
        </Col>
        <Col span={6}>
          <Statistic prefix="¥" title="已收押金 (元)" value={deposit?.received} precision={2}
                     valueStyle={{ color: '#30A694' }}/>
        </Col>
        <Divider type="vertical" style={{ height: 60 }}/>
        <Col span={6}>
          <Statistic prefix="¥" title="未退押金（元）" value={deposit?.not_returned} precision={2}
                     valueStyle={{ color: '#30A694' }}/>
        </Col>
        <Divider type="vertical" style={{ height: 60 }}/>
        <Col span={6}>
          <Statistic prefix="¥" title="待退押金（元）" value={deposit?.to_be_refunded} precision={2}
                     valueStyle={{ color: '#30A694' }}/>
        </Col>
      </Row>
      <Divider/>
      <Tabs defaultActiveKey={type} type="card" onChange={key => {
        setType(key)
        setRowSelection([])
      }}>
        <Tabs.TabPane tab="全部订单" key="0"/>
        <Tabs.TabPane tab="待退款" key="1"/>
        <Tabs.TabPane tab="未退款" key="2"/>
        <Tabs.TabPane tab="已退款" key="3"/>
      </Tabs>
      <Row justify="space-between">
        <Col>
          <Space>
            <Button onClick={() => handleExportExcel()}>导出EXCEL</Button>
          </Space>
        </Col>
        <Row gutter={[20, 0]}>
          <Col>
            <Form.Item>
              <Input
                allowClear
                placeholder="请输入用户姓名/手机号"
                onChange={e => setKeyword(e.target.value !== '' ? e.target.value : undefined)}
                suffix={<SearchOutlined/>}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <DatePicker.RangePicker
                picker="date"
                value={time?.length ? [moment(time[0]), moment(time[1])] : null}
                onChange={(value) => handleChangeTime(value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Row>
      <Table
        loading={listIsFetching}
        rowKey='id'
        rowSelection={{
          selectedRowKeys: rowSelection,
          onChange: selectedRowKeys => {
            setRowSelection(selectedRowKeys)
          }
        }}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          current: page,
          total,
          pageSize,
          showQuickJumper: true,
          showSizeChanger: true,
          onChange: _page => setPage(_page),
          onShowSizeChange: (_, _pageSize) => setPageSize(_pageSize)
        }}
      />
    </Fragment>
  )
}

export default Deposit