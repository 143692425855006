/*
 * @Author: your name
 * @Date: 2021-03-22 09:24:12
 * @LastEditTime: 2021-03-22 15:05:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/InputBox/style.ts
 */
import styled from "styled-components"

export const Body = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const Title = styled.div`
  overflow: hidden;
  white-space: nowrap;  
  margin-right: 5px;
`

export const Input = styled.div`
  
`

export const Mask = styled.div`
  color: #DD4646;
  font-size: 14px;
  position: absolute;
  left:-8px;
  top:2px;
`