/*
 * @Author: your name
 * @Date: 2021-01-21 18:40:25
 * @LastEditTime: 2021-03-22 15:04:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/components/InputBox/index.tsx
 */
import React, { FC, ReactNode, CSSProperties } from 'react'
import { Body, Title, Input, Mask } from './style'

interface SelectProps {
  title?: string,
  children?: ReactNode,
  style?: CSSProperties,
  textStyle?: CSSProperties,
  must?: boolean,
}

const InputBox: FC<SelectProps> = (props) => {
  return (
    <Body style={props.style}>
      {props.must ? <Mask>*</Mask> : null}
      <Title style={props.textStyle}>{props.title}:</Title>
      <Input>{props.children}</Input>
    </Body>
  )
}

export default InputBox