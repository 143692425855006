import React, { FC } from "react";
import { FormInstance, Alert, Row, Col, Form, Checkbox, Typography, InputNumber } from "antd";
import styles from './common.module.css'
import { useUpdate } from "utils/hooks";

interface PaymentProps {
  paymentForm: FormInstance,
}

const Payment: FC<PaymentProps> = (props) => {
  const update = useUpdate()
  const { paymentForm: { getFieldValue } } = props
  return (
    <>
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Alert
            message="当前模式："
            description={
              `${getFieldValue('is_advance_deposit') ? `预付押金${getFieldValue('deposit_amount')}元；` : '' }
              ${getFieldValue('pay_by_hour') ? `按小时计费，储物柜租用时间内
               ${getFieldValue('amount_during_lease_term_hour')} 元/小时，
               超过储物柜租用时间 ${getFieldValue('rental_overdue_amount_hour')} 元/小时；
               ${!getFieldValue('rental_capping_duration_hour')
                ? '' : `存放超过${getFieldValue('rental_capping_duration_hour')} 小时 ${getFieldValue('rental_ceiling_amount_hour')} 元封顶`} ` : ''}
               
             ${getFieldValue('pay_by_day') ? `按天计费，储物柜租用时间内
               ${getFieldValue('amount_during_lease_term_day')} 元/天，
               超过储物柜租用时间 ${getFieldValue('rental_overdue_amount_day')} 元/天；
               ${!getFieldValue('rental_capping_duration_day') ? ''
                : `存放超过${getFieldValue('rental_capping_duration_day')} 天 ${getFieldValue('rental_ceiling_amount_day')} 元封顶`
              } ` : '' }
                
               
                ${getFieldValue('pay_by_month') ? `按月计费，储物柜租用时间内
               ${getFieldValue('amount_during_lease_term_month')} 元/月，
               超过储物柜租用时间 ${getFieldValue('rental_overdue_amount_month')} 元/月；
               ${ !getFieldValue('rental_capping_duration_month') ? '' : `
               存放超过${getFieldValue('rental_capping_duration_month')} 月 ${getFieldValue('rental_ceiling_amount_month')} 元封顶` }
               ` : ''}`
            }
            type="info"
            showIcon
          />
        </Col>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              className={styles.payItem}
              name='is_advance_deposit'
              label={<Typography.Title style={{ margin: 0 }} level={5}>预付押金</Typography.Title>}
            >
              <Checkbox
                onChange={({target}) => {
                  props.paymentForm.setFieldsValue({is_advance_deposit: target.checked})
                  update()
                }}
                checked={props.paymentForm.getFieldValue('is_advance_deposit')}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ marginTop: 24 }}>
            <Form.Item label="押金金额" name="deposit_amount" rules={[{required: true}]} className="custom">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                stringMode
                onFocusCapture={() => {
                  console.log(props.paymentForm.getFieldValue('deposit_amount'))
                  if (props.paymentForm.getFieldValue('deposit_amount') === '0') {
                    props.paymentForm.setFieldsValue({deposit_amount: null})
                  }
                }}
                onBlurCapture={() => {
                  if (props.paymentForm.getFieldValue('deposit_amount') === null) {
                    props.paymentForm.setFieldsValue({deposit_amount: '0'})
                  }
                }}
                step="0.01"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          <Col span={24}  style={{ marginBottom: 24 }}>
            <Form.Item className={styles.payItem} name="pay_by_hour" label={<Typography.Title style={{ margin: 0 }} level={5}>按小时计费</Typography.Title>}>
              <Checkbox
                onChange={({target}) => {
                  props.paymentForm.setFieldsValue({pay_by_hour: target.checked})
                  update()
                }}
                checked={props.paymentForm.getFieldValue('pay_by_hour')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="租用期限内金额（元/小时）" name="amount_during_lease_term_hour">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                step="0.01"
                stringMode
                onFocusCapture={() => {
                  if (props.paymentForm.getFieldValue('amount_during_lease_term_hour') === '0') {
                    props.paymentForm.setFieldsValue({amount_during_lease_term_hour: null})
                  }
                }}
                onBlurCapture={() => {
                  if (props.paymentForm.getFieldValue('amount_during_lease_term_hour') === null) {
                    props.paymentForm.setFieldsValue({amount_during_lease_term_hour: '0'})
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="租用逾期金额（元/小时）" name="rental_overdue_amount_hour">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                step="0.01"
                onFocusCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_overdue_amount_hour') === "0") {
                    props.paymentForm.setFieldsValue({rental_overdue_amount_hour: null})
                  }
                }}
                onBlurCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_overdue_amount_hour') === null) {
                    props.paymentForm.setFieldsValue({rental_overdue_amount_hour: '0'})
                  }
                }}
                stringMode
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="超时封顶时长（小时）" name="rental_capping_duration_hour">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                step="1"
                stringMode
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="超时封顶金额（元）" name="rental_ceiling_amount_hour">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                step="0.01"
                onFocusCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_ceiling_amount_hour') === '0') {
                    props.paymentForm.setFieldsValue({rental_ceiling_amount_hour: null})
                  }
                }}
                onBlurCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_ceiling_amount_hour') === null) {
                    props.paymentForm.setFieldsValue({rental_ceiling_amount_hour: '0'})
                  }
                }}
                stringMode
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          <Col span={24} style={{ marginBottom: 24 }}>
            <Form.Item className={styles.payItem} name="pay_by_day" label={<Typography.Title style={{ margin: 0 }} level={5}>按天计费</Typography.Title>}>
              <Checkbox
                onChange={({target}) => {
                  props.paymentForm.setFieldsValue({pay_by_day: target.checked})
                  update()
                }}
                checked={props.paymentForm.getFieldValue('pay_by_day')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="租用期限内金额（元/天）" name="amount_during_lease_term_day">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                step="0.01"
                onFocusCapture={() => {
                  if (props.paymentForm.getFieldValue('amount_during_lease_term_day') === '0') {
                    props.paymentForm.setFieldsValue({amount_during_lease_term_day: null})
                  }
                }}
                onBlurCapture={() => {
                  if (props.paymentForm.getFieldValue('amount_during_lease_term_day') === null) {
                    props.paymentForm.setFieldsValue({amount_during_lease_term_day: '0'})
                  }
                }}
                stringMode
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="租用逾期金额（元/天）" name="rental_overdue_amount_day">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                step="0.01"
                onFocusCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_overdue_amount_day') === '0') {
                    props.paymentForm.setFieldsValue({rental_overdue_amount_day: null})
                  }
                }}
                onBlurCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_overdue_amount_day') === null) {
                    props.paymentForm.setFieldsValue({rental_overdue_amount_day: '0'})
                  }
                }}
                stringMode
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="超时封顶时长（天）" name="rental_capping_duration_day">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                stringMode
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="超时封顶金额（元）" name="rental_ceiling_amount_day">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                step="0.01"
                onFocusCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_ceiling_amount_day') === '0') {
                    props.paymentForm.setFieldsValue({rental_ceiling_amount_day: null})
                  }
                }}
                onBlurCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_ceiling_amount_day') === null) {
                    props.paymentForm.setFieldsValue({rental_ceiling_amount_day: '0'})
                  }
                }}
                stringMode
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ width: '100%' }}>
          <Col span={24} style={{ marginBottom: 24 }}>
            <Form.Item className={styles.payItem} name="pay_by_month" label={<Typography.Title style={{ margin: 0 }} level={5}>按月计费</Typography.Title>}>
              <Checkbox
                style={{ flexGrow: 'unset' }}
                onChange={({target}) => {
                  props.paymentForm.setFieldsValue({pay_by_month: target.checked})
                  update()
                }}
                checked={props.paymentForm.getFieldValue('pay_by_month')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="租用期限内金额（元/月）" name="amount_during_lease_term_month">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                step="0.01"
                onFocusCapture={() => {
                  if (props.paymentForm.getFieldValue('amount_during_lease_term_month') === '0') {
                    props.paymentForm.setFieldsValue({amount_during_lease_term_month: null})
                  }
                }}
                onBlurCapture={() => {
                  if (props.paymentForm.getFieldValue('amount_during_lease_term_month') === null) {
                    props.paymentForm.setFieldsValue({amount_during_lease_term_month: '0'})
                  }
                }}
                stringMode
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="租用逾期金额（元/月）" name="rental_overdue_amount_month">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                step="0.01"
                onFocusCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_overdue_amount_month') === '0') {
                    props.paymentForm.setFieldsValue({rental_overdue_amount_month: null})
                  }
                }}
                onBlurCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_overdue_amount_month') === null) {
                    props.paymentForm.setFieldsValue({rental_overdue_amount_month: '0'})
                  }
                }}
                stringMode
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="超时封顶时长（月）" name="rental_capping_duration_month">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                stringMode
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="超时封顶金额（元）" name="rental_ceiling_amount_month">
              <InputNumber
                className={styles.formItem}
                placeholder="请输入"
                min={0}
                step="0.01"
                onFocusCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_ceiling_amount_month') === '0') {
                    props.paymentForm.setFieldsValue({rental_ceiling_amount_month: null})
                  }
                }}
                onBlurCapture={() => {
                  if (props.paymentForm.getFieldValue('rental_ceiling_amount_month') === null) {
                    props.paymentForm.setFieldsValue({rental_ceiling_amount_month: '0'})
                  }
                }}
                stringMode
              />
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </>
  )
}

export default Payment