/*
 * @Author: your name
 * @Date: 2021-01-14 11:07:38
 * @LastEditTime: 2021-04-06 14:07:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /joyreserve-phoenix/src/consts/url.ts1
 */
export const ossURL = 'http://storage.joyreserve.com/Public/phoenix'
export const OSS_SIGN = 'https://oss-sign.joyreserve.com'
export const ICONFONTCN = ['https://at.alicdn.com/t/font_2182404_x15ir21acr.js', 'https://at.alicdn.com/t/font_2943603_dsiwojdt7on.js', 'https://at.alicdn.com/t/font_2182404_f0qki6gbeav.js']
export const ICONFONTCN_WECHAT = 'https://at.alicdn.com/t/font_1739290_6fxs7lodzmq.js'
export const baseURL = process.env.NODE_ENV === 'development' ? 'https://demo.reserve.sya.org.cn/index.php' : process.env.REACT_APP_API
