import React, { FC, useCallback, useEffect, useState, Fragment } from "react";
import { Col, Row, message, Descriptions, Table } from "antd";
import { useSearchParams } from "utils/common";
import { Block } from "components";
import Device from "services/device";
import { useHistory } from "react-router-dom";

const Detail: FC = () => {
  const { rend_id } = useSearchParams()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [detail, setDetail] = useState<any>()
  const [dataSource, setDataSource] = useState<any>([])
  const [userInfo, setUserInfo] = useState<any>([])

  const loadDetail = useCallback(() => {
    setLoading(true)
    Device.rentOrderDetail(rend_id).then(res => {
      setDetail(res)
      setUserInfo(Object.entries(res.user_info))
      setDataSource([res.order_info])
    }).catch(e => {
      message.error(e).then(() => history.goBack())
    }).finally(() => {
      setLoading(false)
    })
  }, [rend_id])

  useEffect(loadDetail, [rend_id])

  const columns = [
    {
      key: 'rental_time',
      dataIndex: 'rental_time',
      title: '预约日期'
    },
    {
      key: 'week',
      dataIndex: 'week',
      title: '日期（周）'
    },
    {
      key: 'people',
      dataIndex: 'people',
      title: '人数'
    },
    {
      key: 'locker_number',
      dataIndex: 'locker_number',
      title: '柜子编号'
    },
    {
      key: 'locker_name',
      dataIndex: 'amount',
      title: '柜子名称'
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: '订单类型'
    },
    {
      key: 'state',
      dataIndex: 'state',
      title: '状态'
    },
    {
      key: 'price',
      dataIndex: 'price',
      title: '价格'
    },
  ]

  return (
    <Fragment>
      <Block
        title={`订单编号： #${detail?.order_log.order_id || ''}`}
        loading={loading}
        showBack
      >
        <Descriptions title="已完成-（已签退/预约完成/退款成功）">
          <Descriptions.Item label={'下单时间：'}>{detail?.order_log.created_at}</Descriptions.Item>
          <Descriptions.Item label={'预约方式：'}>用户下单</Descriptions.Item>
          <Descriptions.Item label={'支付时间：'}>{detail?.order_log.created_at}</Descriptions.Item>
        </Descriptions>
      </Block>
      <Block title="账单信息" loading={loading}>
        <Descriptions>
          <Descriptions.Item label="账单编号：">{detail?.bill_info.bill_id}</Descriptions.Item>
          <Descriptions.Item label="支付时间：">{detail?.bill_info.pay_time}</Descriptions.Item>
          <Descriptions.Item label="应收金额：">¥{detail?.bill_info.payable_price} </Descriptions.Item>
          <Descriptions.Item label="账单状态：">{detail?.bill_info.state_name} </Descriptions.Item>
          <Descriptions.Item label="支付方式：">{detail?.bill_info.pay_method_name}</Descriptions.Item>
          <Descriptions.Item label="实收金额：">¥{detail?.bill_info.price}</Descriptions.Item>
        </Descriptions>
      </Block>
      <Block title="用户信息" loading={loading}>
        <Descriptions>
          {
            userInfo.length ? userInfo.map((item, index) => {
              if (typeof item[1] === 'string') {
                return (
                  <Descriptions.Item key={index} label={item[0]}>{item[1]}</Descriptions.Item>
                )
              }
            }) : null
          }
        </Descriptions>
      </Block>

      <Block title="订单信息">
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowKey="rental_time"
        />
        <Row style={{ fontSize: 14, marginTop: 12 }} gutter={[12, 12]}>
          <Col span={22} style={{ textAlign: 'right' }}>订单应收金额: </Col>
          <Col span={2} style={{ textAlign: 'left' }}>¥{detail?.order_info.price}</Col>
          <Col span={22} style={{ textAlign: 'right' }}>合计收款: </Col>
          <Col span={2} style={{ textAlign: 'left' }}>¥{detail?.order_info.price}</Col>
        </Row>
      </Block>
    </Fragment>
  )
}

export default Detail