import React, { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Table, message } from "antd";
import { Block, Header } from "components";
import { useSearchParams } from "utils/common";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import device from "services/device";
import { useDebounceFn } from "utils/hooks";
import { exportExcel } from "components/XLSX";
import { useTranslation } from "react-i18next";

const Logs: FC = () => {
  const { t } = useTranslation()
  const { id, name } = useSearchParams()
  const [listIsFetching, setListIsFetching] = useState<boolean>(false)
  const [dataSource, setDataSource] = useState<any[]>([])
  const [keyword, setKeyword] = useState<string>(undefined)
  const [time, setTime] = useState<string[]>(undefined)
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(20)
  const [total, setTotal] = useState<number>(0)

  const { run: fetchData } = useDebounceFn((keyword: string, time: string[], page: number, page_size: number) => {
    device.lockerLogs({
      lockerId: id,
      keyword,
      start_date: time?.length ? time[0] : void 0,
      end_data: time?.length ? time[1] : void 0,
      page,
      page_size
    }).then(res => {
      setDataSource(res.list);
      setTotal(res.total)
    }).finally(() => {
      setListIsFetching(false)
    })
  }, { wait: 1000 })

  useEffect(() => {
    (async () => {
      setListIsFetching(true)
      await fetchData(keyword, time, page, pageSize)
    })()
  }, [page, pageSize])

  /** 下载全部日志 */
  const handleExport = useCallback(async () => {
    setListIsFetching(true)
    const hide = message.loading('正在导出Excel...')
    const header = ['储物柜名称', '柜子编号', '操作时间', '操作类型', '描述', '操作人', '联系方式']

    try {
      const source = await device.lockerLogs({
        lockerId: id,
        page_size: total,
        keyword,
        start_date: time?.length ? time[0] : void 0,
        end_data: time?.length ? time[1] : void 0,
      })
      let arr = source.list.map(item => ({
        储物柜名称: name,
        柜子编号: item.grid_number,
        操作时间: item.data,
        操作类型: item.type,
        描述: item.describe,
        操作人: item.userName,
        联系方式: item.phone,
      }))
      exportExcel(arr, `${name}日志`, header)
      setListIsFetching(false)
      hide()
    } catch (e) {
      setListIsFetching(false)
      hide()
    }
  }, [name, total, id, keyword, time])

  const handleChangeTime = useCallback(async (value: any) => {
    setListIsFetching(true)
    let temp = value === null ? undefined : [
      value[0].format('YYYY-MM-DD'),
      value[1].format('YYYY-MM-DD')
    ]
    setTime(temp)
    await fetchData(keyword, temp, page, pageSize)
  }, [keyword])

  const columns = [
    {
      key: 'grid_number',
      dataIndex: 'grid_number',
      title: '柜子编号'
    },
    {
      key: 'data',
      dataIndex: 'data',
      title: '操作时间'
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: '操作类型'
    },
    {
      key: 'describe',
      dataIndex: 'describe',
      title: '描述'
    },
    {
      key: 'userName',
      dataIndex: 'userName',
      title: '操作人'
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: '联系方式'
    }
  ]

  return (
    <Fragment>
      <Header
        title={t("page_lockers_title")}
        description={null}
      />
      <Block title={`${name}日志`} loading={listIsFetching} showBack>
        <Row gutter={[20, 0]} style={{ marginTop: '20px' }} justify={'space-between'}>
          <Col>
            <Button type="primary" onClick={handleExport}>导出Excel</Button>
          </Col>
          <Row gutter={[20, 0]}>
            <Col>
              <Form.Item>
                <Input
                  allowClear
                  placeholder="请输入用户姓名/手机号"
                  suffix={<SearchOutlined/>}
                  onChange={async e => {
                    setKeyword(e.target.value)
                    setListIsFetching(true)
                    await fetchData(e.target.value, time, page, pageSize)
                  }}
                  value={keyword}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <DatePicker.RangePicker
                  picker="date"
                  value={time?.length ? [moment(time[0]), moment(time[1])] : null}
                  onChange={(value) => handleChangeTime(value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Row>
        <Table
          rowKey="id"
          loading={listIsFetching}
          dataSource={dataSource}
          columns={columns}
          pagination={{
            current: page,
            total,
            pageSize,
            showQuickJumper: true,
            showSizeChanger: true,
            onChange: _page => setPage(_page),
            onShowSizeChange: (_, _pageSize) => setPageSize(_pageSize)
          }}
        />
      </Block>
    </Fragment>
  )
}

export default Logs